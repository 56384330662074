<template>
    <link href="https://fonts.googleapis.com/css?family=Lato" rel="stylesheet">

    <div id="Container">
      <div id="Container3">
     
      </div>
    </div>
    
     <div id="Container2">

    <!-- SVG content here -->
    
    <div id="svgLogo">
      <svg id="logo" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0.00 0.00 1578.00 731.00">
    
        <g stroke-width="2.00" fill="none" stroke-linecap="butt">
          
          <path stroke="#96a29c" vector-effect="non-scaling-stroke" d="
            M 556.69 396.82
            Q 558.05 396.71 557.49 397.95
            Q 551.46 411.09 540.21 421.41
            C 524.38 435.92 507.34 448.23 489.19 460.29
            Q 486.36 462.17 483.15 463.78
            C 480.36 465.18 481.61 468.97 482.83 470.87
            A 1.08 1.07 64.6 0 0 484.06 471.32
            Q 488.77 469.79 493.33 468.36
            C 500.36 466.15 507.08 463.51 514.10 461.25
            C 522.66 458.50 530.69 454.55 538.87 451.17
            Q 544.98 448.65 548.04 444.85
            C 552.28 439.58 556.64 433.39 559.64 427.15
            Q 562.83 420.50 565.33 414.26
            Q 568.87 405.45 570.55 393.46
            A 2.81 2.80 -6.8 0 1 572.32 391.22
            Q 579.41 388.44 585.22 384.21
            Q 594.13 377.73 602.88 368.92
            Q 611.75 360.00 619.54 352.46
            C 622.39 349.70 628.02 344.65 632.26 345.28
            C 638.77 346.26 644.51 349.64 650.35 351.95
            C 659.19 355.46 666.10 350.67 670.88 343.54
            Q 677.33 333.92 680.13 323.28
            C 681.18 319.28 679.71 315.28 676.39 312.95
            Q 670.53 308.84 662.92 304.56
            Q 657.41 301.46 657.66 296.11
            Q 658.03 288.19 663.71 281.43
            C 669.71 274.29 679.51 271.30 688.88 271.10
            Q 694.13 271.00 698.02 273.82
            C 702.46 277.05 707.18 280.51 712.79 280.96
            Q 717.85 281.36 721.33 280.21
            Q 726.59 278.49 731.76 274.17
            C 736.13 270.53 740.77 264.12 743.35 259.16
            Q 747.14 251.88 751.19 242.15
            C 754.23 234.85 756.03 227.22 758.31 219.70
            C 760.58 212.19 762.17 203.86 763.15 196.24
            C 764.99 181.98 766.45 167.76 767.20 153.38
            Q 768.61 126.01 770.19 94.29
            C 770.81 81.94 771.43 69.83 773.23 57.61
            A 1.04 1.04 0.0 0 0 772.62 56.51
            L 768.87 54.81
            Q 767.20 54.05 766.57 55.77
            Q 763.24 64.82 759.54 74.26
            Q 756.51 82.00 754.82 88.93
            C 752.17 99.80 749.70 110.79 748.33 121.93
            Q 747.34 129.90 747.16 139.41
            C 746.71 164.09 745.82 189.44 744.16 214.00
            C 743.43 224.81 742.03 234.04 737.13 243.50
            Q 734.64 248.31 730.36 249.69
            C 725.31 251.32 720.39 251.27 716.24 247.98
            C 712.21 244.77 708.36 241.82 703.02 241.22
            C 676.21 238.21 654.29 273.33 644.78 294.26
            Q 642.01 300.35 640.14 309.24
            C 639.98 310.02 639.58 311.25 638.96 311.76
            Q 631.69 317.78 626.86 324.41
            Q 621.02 332.41 615.04 340.78
            Q 604.37 355.71 588.84 364.07
            C 583.62 366.88 579.09 368.02 573.80 369.63
            Q 572.70 369.97 572.61 368.81
            C 571.90 359.45 571.22 350.03 568.19 341.40
            C 563.27 327.38 548.22 320.88 535.69 330.18
            Q 532.36 332.65 529.23 338.39
            Q 525.25 345.69 520.59 355.46
            C 516.55 363.96 513.94 369.63 513.74 378.13
            C 513.52 387.68 520.72 395.69 529.74 397.47
            C 538.78 399.24 547.62 397.57 556.69 396.82"
          />
          <path stroke="#96a29c" vector-effect="non-scaling-stroke" d="
            M 666.68 161.21
            Q 674.66 168.50 682.79 176.08
            Q 684.63 177.79 686.73 176.42
            Q 696.41 170.12 704.24 160.89
            C 709.03 155.23 710.48 147.76 705.41 141.60
            C 700.91 136.14 694.13 131.60 688.19 127.50
            C 686.35 126.22 684.36 125.97 682.59 127.62
            Q 672.69 136.83 664.81 146.62
            A 0.89 0.88 46.9 0 1 663.39 146.57
            C 659.12 140.40 651.90 135.89 645.30 132.05
            C 643.93 131.25 642.72 131.63 641.51 132.53
            Q 638.89 134.46 637.42 136.01
            Q 627.00 146.99 618.32 158.06
            C 617.43 159.20 617.29 160.75 618.51 161.72
            Q 630.47 171.27 640.57 181.36
            C 642.66 183.45 645.38 181.27 647.25 179.79
            Q 651.72 176.24 655.88 172.66
            C 659.78 169.29 662.79 165.52 665.68 161.33
            A 0.67 0.66 -51.4 0 1 666.68 161.21"
          />
          <path stroke="#96a29c" vector-effect="non-scaling-stroke" d="
            M 1279.88 196.78
            C 1283.90 193.07 1291.23 184.54 1289.15 178.79
            C 1285.45 168.56 1276.95 163.82 1268.56 157.87
            Q 1265.93 156.01 1263.46 158.34
            Q 1252.36 168.77 1241.56 182.77
            C 1240.39 184.29 1239.14 186.05 1241.25 187.65
            Q 1252.09 195.90 1261.99 206.01
            C 1263.57 207.62 1264.88 208.59 1266.83 207.20
            Q 1274.52 201.73 1279.88 196.78"
          />
          <path stroke="#96a29c" vector-effect="non-scaling-stroke" d="
            M 1176.08 188.39
            Q 1175.72 188.27 1175.53 187.98
            C 1173.23 184.43 1170.62 182.53 1167.45 180.08
            Q 1162.46 176.23 1157.59 173.25
            Q 1156.57 172.62 1155.49 172.81
            C 1153.59 173.15 1150.66 176.28 1149.27 177.71
            Q 1138.80 188.51 1130.88 199.11
            Q 1128.97 201.67 1131.41 203.65
            Q 1141.98 212.24 1151.21 221.25
            C 1155.34 225.29 1159.05 221.27 1162.34 218.79
            Q 1171.50 211.86 1178.11 202.46
            A 0.64 0.64 0.0 0 1 1179.13 202.41
            Q 1182.09 205.93 1185.62 208.88
            C 1189.08 211.77 1192.14 215.02 1195.44 218.06
            Q 1196.95 219.45 1198.60 218.23
            Q 1205.62 213.07 1211.40 207.65
            C 1219.32 200.23 1225.20 190.46 1215.63 180.92
            Q 1208.09 173.40 1198.21 168.01
            C 1197.25 167.48 1196.15 167.88 1195.39 168.55
            Q 1185.20 177.55 1176.88 188.14
            A 0.74 0.73 27.3 0 1 1176.08 188.39"
          />
          <path stroke="#96a29c" vector-effect="non-scaling-stroke" d="
            M 838.51 422.74
            C 841.38 415.94 844.57 409.08 846.63 402.01
            Q 849.48 392.20 852.11 382.47
            C 855.53 369.78 857.16 356.07 857.87 342.02
            C 858.82 323.08 859.85 304.95 861.30 286.52
            C 862.82 267.14 863.71 244.93 866.07 224.19
            Q 866.79 217.85 868.25 212.01
            Q 868.57 210.72 868.36 209.42
            Q 868.26 208.82 867.69 208.64
            L 864.71 207.69
            Q 863.57 207.33 863.01 208.38
            Q 859.02 215.83 856.22 223.47
            C 853.81 230.08 850.75 236.35 848.48 243.01
            Q 840.58 266.30 839.79 292.00
            Q 838.64 329.97 836.68 379.14
            Q 835.79 401.55 832.22 421.24
            Q 832.05 422.22 832.98 422.56
            Q 835.23 423.38 837.60 423.35
            A 1.01 0.99 10.7 0 0 838.51 422.74"
          />
          <path stroke="#96a29c" vector-effect="non-scaling-stroke" d="
            M 981.58 269.42
            Q 986.58 273.95 991.52 278.37
            C 993.90 280.51 996.08 283.25 998.75 285.13
            Q 1000.19 286.15 1001.68 285.12
            Q 1009.03 280.01 1015.68 273.36
            C 1023.35 265.68 1027.77 256.88 1018.80 248.01
            C 1013.62 242.89 1008.09 238.78 1001.94 235.37
            C 1000.36 234.49 998.61 235.31 997.41 236.40
            Q 988.48 244.49 979.89 254.97
            A 0.91 0.90 49.5 0 1 978.41 254.85
            Q 976.63 251.74 973.67 249.45
            Q 966.63 244.00 960.15 239.98
            Q 958.78 239.13 957.43 240.15
            Q 954.91 242.03 952.77 244.21
            Q 942.45 254.70 933.49 266.61
            Q 931.91 268.71 933.91 270.30
            Q 945.34 279.41 956.08 289.95
            A 2.40 2.39 50.5 0 0 959.07 290.24
            C 966.98 285.04 976.26 277.79 980.53 269.61
            Q 980.93 268.84 981.58 269.42"
          />
          <path stroke="#96a29c" vector-effect="non-scaling-stroke" d="
            M 1101.47 396.61
            Q 1103.33 396.33 1102.64 398.07
            Q 1099.29 406.52 1094.12 412.61
            Q 1086.67 421.37 1075.50 430.50
            Q 1055.81 446.58 1034.55 460.38
            Q 1031.45 462.39 1027.97 464.34
            C 1025.89 465.50 1027.06 469.27 1027.94 470.86
            A 1.21 1.20 -22.9 0 0 1029.36 471.43
            Q 1045.19 466.36 1060.93 460.82
            C 1069.44 457.81 1077.69 454.08 1085.78 450.55
            C 1093.86 447.01 1098.64 438.45 1103.18 431.10
            C 1106.20 426.20 1108.00 421.05 1110.18 415.82
            Q 1114.45 405.59 1116.22 394.60
            A 1.16 1.16 0.0 0 1 1117.10 393.65
            Q 1127.60 391.15 1137.34 388.19
            Q 1144.61 385.97 1149.31 383.53
            C 1155.39 380.38 1161.76 377.32 1167.48 373.69
            Q 1174.77 369.07 1182.01 364.37
            A 3.80 3.71 28.8 0 1 1184.20 363.76
            Q 1189.30 363.90 1194.24 363.86
            Q 1196.23 363.84 1198.18 364.63
            C 1204.67 367.26 1216.37 363.08 1222.60 360.29
            C 1233.19 355.55 1242.87 345.57 1249.26 335.77
            Q 1253.81 328.82 1255.87 322.36
            Q 1258.65 313.66 1259.87 305.99
            Q 1262.54 289.14 1264.05 269.10
            C 1264.25 266.48 1264.83 263.64 1265.31 261.02
            A 1.69 1.68 20.4 0 0 1264.49 259.25
            C 1262.92 258.34 1259.82 256.64 1258.52 258.80
            Q 1255.30 264.14 1252.27 268.95
            C 1247.86 275.94 1245.57 283.58 1245.31 291.49
            Q 1245.02 300.15 1244.69 309.40
            C 1244.36 318.62 1242.28 327.99 1233.98 333.04
            C 1227.18 337.17 1217.86 342.02 1209.68 339.59
            A 0.43 0.43 0.0 0 1 1209.41 338.99
            Q 1211.13 335.39 1212.98 331.78
            C 1217.74 322.52 1219.17 311.75 1218.59 300.96
            C 1218.11 292.26 1214.44 284.82 1208.32 278.80
            C 1203.02 273.59 1194.08 272.36 1186.65 273.10
            Q 1175.56 274.20 1167.01 282.27
            C 1157.03 291.69 1151.90 302.34 1147.82 315.19
            C 1145.26 323.26 1144.02 336.02 1148.20 344.05
            Q 1151.31 350.05 1156.84 355.18
            A 1.34 1.33 -36.0 0 1 1156.49 357.37
            Q 1147.02 361.75 1137.77 365.49
            Q 1131.94 367.86 1118.97 369.84
            Q 1118.34 369.93 1118.31 369.30
            C 1117.36 353.56 1116.25 329.27 1096.25 326.02
            C 1089.80 324.97 1082.24 328.07 1078.28 333.23
            Q 1074.71 337.88 1072.36 342.88
            Q 1068.72 350.66 1064.39 359.07
            Q 1060.63 366.38 1059.67 375.44
            C 1058.54 386.23 1064.44 395.08 1075.16 397.40
            Q 1081.08 398.68 1087.29 398.21
            Q 1094.63 397.66 1101.47 396.61"
          />
          <path stroke="#96a29c" vector-effect="non-scaling-stroke" d="
            M 438.40 369.05
            Q 435.54 375.98 430.30 384.89
            C 426.73 390.96 422.49 395.98 417.69 400.71
            Q 390.70 427.38 359.82 449.30
            Q 357.73 450.78 350.55 454.80
            Q 349.47 455.40 348.77 456.42
            A 1.29 1.23 59.5 0 0 348.56 457.32
            Q 348.88 459.58 349.79 461.43
            A 1.32 1.31 -19.1 0 0 351.25 462.13
            C 358.31 460.59 365.34 459.09 372.26 457.08
            Q 382.02 454.25 391.06 451.97
            Q 396.09 450.71 400.92 448.90
            C 406.22 446.92 412.35 444.57 416.26 440.35
            Q 423.14 432.92 428.33 423.94
            Q 431.09 419.18 434.02 413.85
            C 437.64 407.27 440.15 400.35 443.14 393.74
            C 447.13 384.92 449.67 375.50 452.70 366.50
            C 456.08 356.47 458.52 345.91 460.91 335.65
            Q 461.12 334.70 460.18 334.50
            L 454.00 333.16
            A 1.26 1.26 0.0 0 0 452.54 333.96
            Q 449.59 341.93 446.55 350.33
            C 444.28 356.59 441.05 362.66 438.40 369.05"
          />
          <path stroke="#96a29c" vector-effect="non-scaling-stroke" d="
            M 946.18 449.87
            C 952.70 454.87 962.60 454.28 970.22 452.55
            C 983.89 449.45 996.32 437.83 1003.28 426.24
            Q 1007.66 418.96 1011.09 409.21
            C 1015.01 398.09 1015.43 382.87 1013.08 371.45
            C 1009.93 356.18 998.95 341.86 983.01 338.62
            C 975.42 337.07 970.95 343.33 967.34 348.96
            C 962.84 355.96 958.75 362.84 957.94 370.81
            C 957.73 372.96 959.62 375.14 957.90 377.04
            Q 950.17 385.61 943.70 399.29
            Q 938.89 409.48 937.63 424.05
            C 936.75 434.38 937.76 443.42 946.18 449.87"
          />
          <path stroke="#97a29c" vector-effect="non-scaling-stroke" d="
            M 78.14 636.35
            Q 79.23 636.62 79.96 637.41
            Q 93.06 651.55 104.42 667.00
            C 105.34 668.26 106.84 668.56 108.30 668.46
            Q 109.48 668.38 109.48 667.21
            Q 109.48 657.77 109.17 648.34
            Q 108.88 639.28 109.83 632.37
            C 110.16 629.99 113.86 629.43 115.72 628.70
            Q 116.56 628.37 116.37 628.05
            A 1.60 1.52 -15.6 0 0 114.97 627.31
            Q 107.55 627.53 99.85 627.38
            A 0.85 0.85 0.0 0 0 99.02 628.00
            Q 98.86 628.61 99.47 628.82
            Q 102.01 629.70 104.44 630.62
            A 1.98 1.95 -85.7 0 1 105.66 632.06
            C 107.10 639.24 106.79 647.18 106.85 654.98
            Q 106.86 655.54 106.58 656.02
            A 0.50 0.49 43.5 0 1 105.74 656.04
            Q 103.45 652.54 101.20 649.95
            Q 91.02 638.26 82.68 628.15
            A 2.18 2.17 -20.0 0 0 80.98 627.36
            L 67.45 627.54
            A 0.74 0.74 0.0 0 0 66.74 628.45
            Q 66.89 629.05 67.75 629.24
            C 70.60 629.89 75.56 630.91 75.52 634.85
            Q 75.38 646.57 75.18 658.58
            Q 75.12 661.76 74.04 664.62
            A 1.05 1.05 0.0 0 1 73.33 665.26
            L 66.76 666.99
            Q 66.55 667.04 66.57 667.26
            L 66.59 667.52
            Q 66.63 668.12 67.24 668.13
            Q 75.39 668.29 85.07 668.21
            Q 85.43 668.20 85.71 667.92
            Q 86.54 667.07 85.38 666.81
            Q 83.02 666.28 80.84 665.60
            C 77.95 664.72 77.87 659.92 77.86 657.51
            Q 77.78 647.41 77.84 636.59
            A 0.25 0.24 -83.5 0 1 78.14 636.35"
          />
          <path stroke="#97a29c" vector-effect="non-scaling-stroke" d="
            M 502.20 656.98
            Q 501.78 658.30 501.16 657.06
            Q 495.17 644.95 492.14 631.77
            A 1.60 1.60 0.0 0 1 493.02 629.96
            Q 494.39 629.31 496.00 628.98
            Q 496.75 628.83 496.90 628.15
            A 0.70 0.69 -84.5 0 0 496.20 627.30
            Q 487.81 627.54 479.24 627.39
            Q 478.40 627.38 477.83 627.77
            A 0.54 0.54 0.0 0 0 477.98 628.74
            Q 482.17 629.99 483.51 633.20
            C 484.80 636.31 487.02 640.08 485.39 643.41
            Q 484.50 645.22 484.00 647.25
            Q 482.71 652.62 479.45 657.20
            A 0.41 0.40 -35.1 0 1 478.73 657.08
            Q 475.25 644.24 470.29 632.71
            C 468.93 629.54 472.33 629.10 474.39 628.57
            Q 475.21 628.37 474.56 627.83
            Q 474.05 627.42 473.38 627.42
            Q 465.23 627.46 457.16 627.53
            Q 456.65 627.53 456.42 627.98
            Q 456.13 628.56 456.75 628.75
            Q 460.55 629.93 461.81 632.72
            Q 464.29 638.22 467.08 646.98
            Q 469.36 654.12 472.61 661.83
            Q 473.37 663.62 473.98 665.57
            Q 475.79 671.36 478.21 665.80
            Q 480.12 661.40 482.09 657.36
            Q 485.08 651.23 486.44 645.10
            A 0.69 0.68 28.9 0 1 487.60 644.77
            Q 488.20 645.38 488.51 646.14
            Q 492.58 656.06 495.96 666.84
            C 496.38 668.15 497.61 668.83 498.92 668.31
            Q 499.33 668.15 499.53 667.75
            Q 502.45 661.75 504.46 655.60
            Q 506.13 650.51 508.27 646.48
            Q 511.51 640.40 513.93 633.92
            C 515.00 631.02 517.30 629.85 520.05 628.98
            Q 520.56 628.82 520.83 628.35
            Q 521.14 627.82 520.06 627.42
            A 1.42 1.20 49.7 0 0 519.64 627.35
            Q 513.14 627.40 507.10 627.36
            Q 506.41 627.35 505.97 627.87
            A 0.70 0.69 -60.2 0 0 506.27 628.98
            L 510.32 630.35
            A 1.37 1.36 -79.2 0 1 511.25 631.73
            Q 511.14 633.80 510.42 635.75
            Q 508.30 641.50 505.48 647.66
            C 504.10 650.68 503.19 653.83 502.20 656.98"
          />
          <path stroke="#97a29c" vector-effect="non-scaling-stroke" d="
            M 736.48 643.77
            L 736.74 632.40
            Q 736.76 631.69 737.22 631.16
            C 738.57 629.61 741.22 629.19 743.13 628.72
            A 0.53 0.52 -25.2 0 0 743.43 627.90
            Q 743.00 627.33 742.29 627.35
            Q 732.33 627.55 723.11 627.38
            A 0.97 0.97 0.0 0 0 722.22 627.93
            Q 721.90 628.59 722.62 628.84
            Q 724.49 629.50 726.28 630.10
            A 2.53 2.53 0.0 0 1 727.99 632.48
            Q 728.10 647.67 727.91 662.25
            Q 727.89 663.71 727.27 664.81
            Q 726.99 665.30 726.45 665.45
            L 721.72 666.77
            A 0.47 0.47 0.0 0 0 721.46 667.49
            Q 721.80 668.00 722.40 668.04
            Q 732.29 668.76 743.04 668.04
            A 0.74 0.73 -82.2 0 0 743.69 667.54
            Q 743.93 666.81 743.18 666.65
            Q 740.73 666.09 738.45 665.46
            A 1.72 1.71 -12.4 0 1 737.57 664.90
            Q 736.48 663.60 736.51 660.76
            Q 736.60 654.63 736.56 647.89
            A 0.57 0.57 0.0 0 1 737.10 647.32
            Q 746.70 646.79 756.44 647.15
            A 0.93 0.93 0.0 0 1 757.34 648.06
            Q 757.48 654.89 757.20 662.37
            C 757.07 666.02 753.74 665.96 751.13 666.62
            Q 750.56 666.76 750.85 667.47
            A 0.73 0.73 0.0 0 0 751.46 667.92
            Q 761.92 668.80 772.28 668.01
            Q 772.78 667.98 773.00 667.63
            A 0.64 0.64 0.0 0 0 772.58 666.66
            C 770.19 666.19 766.05 666.14 765.95 662.68
            Q 765.57 650.57 766.00 632.85
            C 766.08 629.81 769.68 629.27 771.89 628.75
            Q 773.54 628.36 772.03 627.59
            Q 771.45 627.29 770.79 627.30
            Q 761.52 627.45 752.14 627.39
            A 0.96 0.95 -79.2 0 0 751.25 628.00
            Q 751.14 628.30 751.32 628.58
            Q 751.55 628.91 751.95 629.01
            C 755.42 629.87 757.20 630.05 757.27 634.26
            Q 757.37 639.64 757.29 644.35
            A 0.81 0.81 0.0 0 1 756.49 645.14
            L 737.87 645.19
            Q 736.44 645.19 736.48 643.77"
          />
          <path stroke="#97a29c" vector-effect="non-scaling-stroke" d="
            M 957.82 630.35
            A 1.57 1.56 -77.6 0 1 958.72 631.77
            Q 958.80 646.84 958.70 661.50
            C 958.67 666.29 955.79 665.41 952.54 666.58
            Q 952.21 666.70 952.08 667.03
            A 0.71 0.71 0.0 0 0 952.70 668.00
            C 956.42 668.20 960.22 668.52 963.95 668.43
            Q 973.65 668.18 983.98 667.91
            Q 996.99 667.56 1001.21 654.59
            Q 1003.41 647.82 1000.01 640.69
            Q 993.92 627.91 979.61 627.58
            Q 968.12 627.31 953.14 627.47
            A 0.83 0.83 0.0 0 0 952.33 628.21
            L 952.30 628.51
            A 0.45 0.45 0.0 0 0 952.70 629.00
            Q 955.65 629.34 957.82 630.35"
          />
          <path stroke="#97a29c" vector-effect="non-scaling-stroke" d="
            M 1220.95 631.11
            Q 1220.75 646.40 1220.72 662.17
            Q 1220.72 663.89 1219.91 665.51
            Q 1219.65 666.04 1219.07 666.14
            L 1215.05 666.87
            Q 1214.62 666.95 1214.57 667.38
            L 1214.53 667.70
            A 0.24 0.24 0.0 0 0 1214.75 667.97
            Q 1223.91 668.87 1235.60 668.05
            Q 1236.08 668.01 1236.32 667.64
            A 0.43 0.42 -68.3 0 0 1236.05 667.00
            L 1231.21 665.96
            A 1.86 1.85 -87.8 0 1 1229.75 664.38
            Q 1229.40 661.59 1229.42 658.50
            Q 1229.49 644.44 1229.33 630.96
            A 1.69 1.68 -89.0 0 1 1231.09 629.25
            Q 1235.37 629.44 1238.80 630.90
            C 1240.61 631.68 1242.59 637.35 1243.23 639.29
            A 0.59 0.58 50.1 0 0 1244.30 639.39
            Q 1244.64 638.76 1244.56 638.01
            Q 1243.96 632.93 1244.13 628.26
            A 0.75 0.75 0.0 0 0 1243.39 627.49
            L 1207.25 627.36
            A 0.83 0.83 0.0 0 0 1206.41 628.20
            Q 1206.45 633.15 1206.18 638.42
            A 1.29 1.04 -57.4 0 0 1206.21 638.69
            Q 1206.43 639.50 1206.92 639.51
            Q 1207.53 639.53 1207.73 638.95
            C 1208.85 635.62 1209.37 631.54 1213.12 630.25
            Q 1216.00 629.25 1219.29 629.36
            A 1.73 1.72 -88.6 0 1 1220.95 631.11"
          />
          <path stroke="#97a29c" vector-effect="non-scaling-stroke" d="
            M 206.73 665.05
            Q 204.94 664.71 205.36 662.93
            Q 206.60 657.70 208.87 651.76
            Q 209.37 650.44 210.79 650.47
            L 220.96 650.71
            A 1.36 1.35 -9.0 0 1 222.21 651.61
            Q 223.95 656.49 225.59 661.73
            C 227.02 666.30 222.73 665.57 220.15 666.39
            Q 219.55 666.58 219.60 667.23
            A 0.94 0.94 0.0 0 0 220.48 668.09
            Q 230.73 668.81 241.11 668.10
            Q 241.52 668.07 241.82 667.83
            Q 242.15 667.57 242.07 667.16
            A 0.89 0.88 88.2 0 0 241.31 666.44
            Q 238.58 666.11 236.21 664.69
            A 1.73 1.69 3.4 0 1 235.54 663.95
            C 234.08 660.73 233.24 657.32 231.84 654.04
            Q 226.67 641.87 222.22 628.57
            Q 221.76 627.22 220.39 627.60
            L 215.20 629.02
            Q 214.39 629.24 214.48 630.07
            Q 214.95 634.26 212.86 637.74
            C 209.97 642.53 207.60 647.49 205.74 652.83
            Q 204.51 656.37 202.53 660.44
            Q 200.04 665.55 195.84 666.29
            A 0.85 0.85 0.0 0 0 195.16 667.35
            Q 195.32 667.96 195.97 668.01
            Q 202.29 668.50 208.94 668.17
            Q 211.85 668.02 209.53 666.26
            Q 208.33 665.35 206.73 665.05"
          />
          <path stroke="#97a29c" vector-effect="non-scaling-stroke" d="
            M 353.17 627.57
            Q 346.22 627.10 341.17 628.15
            C 333.80 629.67 327.33 635.18 325.73 642.54
            C 323.11 654.57 328.67 665.42 341.49 667.57
            Q 350.24 669.03 359.90 666.82
            Q 364.98 665.66 369.26 660.53
            Q 373.99 654.85 373.38 646.11
            C 372.63 635.44 363.42 628.27 353.17 627.57"
          />
          <path stroke="#97a29c" vector-effect="non-scaling-stroke" d="
            M 603.44 663.93
            Q 603.29 662.40 603.77 660.94
            Q 605.31 656.34 606.81 651.65
            A 1.51 1.50 8.5 0 1 608.23 650.61
            L 618.24 650.53
            A 1.88 1.87 -10.9 0 1 620.00 651.72
            Q 621.62 655.89 623.24 660.35
            Q 624.03 662.51 623.63 664.64
            Q 623.52 665.22 622.93 665.34
            L 617.96 666.31
            A 0.75 0.75 0.0 0 0 617.47 667.46
            Q 617.81 667.99 618.47 668.04
            Q 629.17 668.85 639.03 668.12
            Q 639.59 668.08 639.73 667.54
            Q 639.81 667.24 639.71 666.95
            A 0.91 0.90 -5.0 0 0 639.01 666.37
            C 636.80 666.00 634.02 665.45 633.17 663.08
            C 630.55 655.71 627.04 648.50 624.54 640.91
            Q 622.43 634.49 619.69 628.35
            A 1.34 1.32 -18.9 0 0 618.14 627.60
            L 613.25 628.84
            Q 612.65 629.00 612.65 629.62
            C 612.68 631.64 612.77 633.19 611.76 635.08
            C 608.89 640.47 606.32 645.56 604.29 651.53
            Q 602.31 657.34 597.95 663.97
            C 596.79 665.73 593.44 665.72 593.03 667.00
            A 0.78 0.78 0.0 0 0 593.71 668.02
            Q 600.39 668.56 607.56 668.08
            Q 608.00 668.05 608.34 667.76
            Q 608.66 667.49 608.55 667.06
            Q 608.39 666.42 607.78 666.19
            L 604.15 664.84
            Q 603.51 664.61 603.44 663.93"
          />
          <path stroke="#97a29c" vector-effect="non-scaling-stroke" d="
            M 1113.42 665.28
            L 1108.05 666.56
            A 0.55 0.55 0.0 0 0 1107.84 667.52
            Q 1108.32 667.90 1108.98 667.96
            Q 1119.00 668.89 1129.07 668.12
            Q 1129.77 668.07 1129.73 667.36
            L 1129.70 666.94
            A 0.78 0.76 1.4 0 0 1129.01 666.23
            C 1127.11 666.04 1124.76 665.34 1123.90 663.43
            Q 1118.98 652.51 1115.32 641.58
            Q 1112.96 634.55 1109.66 628.01
            A 0.92 0.91 -20.6 0 0 1108.61 627.54
            L 1103.50 628.93
            A 0.87 0.86 82.9 0 0 1102.87 629.75
            C 1102.86 631.51 1102.91 633.14 1102.06 634.78
            Q 1098.75 641.18 1096.06 648.49
            C 1094.39 653.01 1091.82 657.18 1089.95 661.60
            Q 1088.37 665.34 1083.92 666.30
            A 1.20 1.15 -74.0 0 0 1083.35 666.63
            Q 1082.89 667.12 1083.14 667.53
            Q 1083.41 667.98 1083.94 668.02
            Q 1091.29 668.58 1098.47 668.04
            A 0.86 0.86 0.0 0 0 1099.27 667.18
            Q 1099.27 666.71 1098.79 666.53
            Q 1096.71 665.77 1094.81 664.87
            A 2.20 2.19 -67.4 0 1 1093.69 662.13
            L 1097.62 651.51
            A 1.31 1.30 -80.2 0 1 1098.83 650.65
            L 1108.26 650.52
            A 2.11 2.10 -12.2 0 1 1110.20 651.77
            Q 1112.43 656.77 1114.05 661.20
            Q 1114.59 662.68 1114.30 664.34
            Q 1114.17 665.10 1113.42 665.28"
          />
          <path stroke="#8f9b98" vector-effect="non-scaling-stroke" d="
            M 1351.33 668.12
            L 1364.68 668.21
            A 2.37 2.36 -81.5 0 0 1366.96 666.52
            Q 1368.36 661.81 1370.77 656.39
            A 0.88 0.87 -59.4 0 0 1370.50 655.33
            L 1370.28 655.16
            A 0.40 0.40 0.0 0 0 1369.69 655.29
            C 1367.04 660.18 1364.96 663.30 1359.74 664.98
            Q 1351.72 667.56 1343.99 666.18"
          />
          <path stroke="#96a29c" vector-effect="non-scaling-stroke" d="
            M 1343.99 666.18
            C 1343.45 665.96 1343.03 665.38 1343.02 664.79
            Q 1342.91 656.55 1342.87 648.08
            A 0.99 0.98 -2.5 0 1 1343.77 647.10
            Q 1346.53 646.88 1349.17 647.31
            Q 1355.14 648.29 1356.67 654.41
            Q 1356.82 655.02 1357.44 655.12
            L 1357.77 655.18
            Q 1358.33 655.28 1358.24 654.71
            Q 1356.86 646.54 1358.14 639.00
            Q 1358.22 638.49 1357.94 638.08
            A 0.69 0.69 0.0 0 0 1356.75 638.19
            C 1354.26 643.65 1350.31 645.35 1344.53 645.46
            A 1.44 1.44 0.0 0 1 1343.07 644.04
            L 1342.85 630.96
            A 1.68 1.67 -2.9 0 1 1344.39 629.26
            Q 1350.77 628.71 1356.25 629.96
            C 1360.46 630.92 1362.85 634.34 1364.59 637.96
            Q 1364.90 638.60 1365.59 638.50
            Q 1366.18 638.41 1366.10 637.82
            Q 1365.49 632.97 1365.76 628.32
            A 0.78 0.78 0.0 0 0 1364.98 627.49
            Q 1347.12 627.47 1329.25 627.50
            Q 1328.68 627.50 1328.47 628.05
            Q 1328.27 628.58 1328.79 628.79
            C 1331.62 629.91 1334.56 630.32 1334.65 634.17
            Q 1334.99 648.26 1334.67 661.22
            C 1334.55 665.75 1331.74 665.70 1328.38 666.69
            A 0.50 0.50 0.0 0 0 1328.23 667.57
            Q 1328.59 667.83 1329.26 667.87
            Q 1340.21 668.61 1351.33 668.12"
          />
          <path stroke="#263e34" vector-effect="non-scaling-stroke" d="
            M 1343.99 666.18
            Q 1343.23 665.98 1342.91 666.80
            A 0.80 0.79 -78.8 0 0 1343.63 667.88
            L 1351.33 668.12"
          />
          <path stroke="#96a29c" vector-effect="non-scaling-stroke" d="
            M 1479.17 661.58
            Q 1478.96 664.61 1476.43 665.58
            C 1471.04 667.64 1463.03 666.32 1459.25 661.58
            Q 1456.41 658.00 1454.29 654.56
            A 0.46 0.45 47.8 0 0 1453.54 654.52
            Q 1453.16 655.00 1453.31 655.60
            Q 1454.83 661.56 1455.57 667.25
            A 0.63 0.62 -7.1 0 0 1456.26 667.78
            C 1459.87 667.33 1463.29 667.43 1466.87 667.90
            Q 1472.98 668.69 1479.18 667.11
            C 1485.21 665.59 1487.92 659.39 1486.06 653.61
            C 1484.83 649.75 1480.70 646.74 1477.01 645.10
            Q 1470.23 642.08 1465.46 639.36
            Q 1461.02 636.83 1462.83 631.96
            C 1464.29 628.02 1471.98 628.94 1474.60 630.13
            C 1478.28 631.80 1481.18 636.10 1482.70 639.86
            A 0.79 0.78 81.6 0 0 1483.34 640.34
            Q 1484.09 640.41 1484.22 639.96
            Q 1484.29 639.73 1484.27 639.50
            Q 1483.85 634.75 1484.03 629.96
            A 0.58 0.57 5.0 0 0 1483.53 629.37
            Q 1479.50 628.85 1475.55 628.04
            Q 1470.55 627.01 1464.47 627.65
            Q 1458.58 628.26 1456.10 632.56
            C 1453.09 637.78 1454.83 643.99 1460.12 647.04
            Q 1465.12 649.93 1471.55 652.98
            C 1475.76 654.98 1479.50 656.64 1479.17 661.58"
          />
          <path stroke="#97a29c" vector-effect="non-scaling-stroke" d="
            M 330.04 673.84
            C 338.08 671.35 345.61 676.20 352.05 680.15
            C 356.78 683.04 364.89 683.85 369.54 679.96
            C 371.50 678.32 373.23 676.41 374.99 674.47
            Q 375.80 673.58 375.60 672.56
            A 0.71 0.71 0.0 0 0 374.42 672.18
            Q 367.79 678.41 357.54 675.55
            Q 351.19 673.77 345.55 672.17
            Q 338.63 670.22 331.22 671.87
            Q 330.05 672.13 329.40 672.67
            Q 326.84 674.83 330.04 673.84"
          />
          <path stroke="#96a29c" vector-effect="non-scaling-stroke" d="
            M 1188.77 338.82
            C 1197.04 333.41 1205.81 326.62 1201.56 315.15
            C 1197.89 305.23 1190.17 301.41 1179.95 302.56
            C 1171.73 303.49 1161.41 308.62 1160.25 317.72
            Q 1158.84 328.81 1167.40 334.33
            Q 1175.02 339.24 1185.09 339.79
            Q 1187.13 339.90 1188.77 338.82"
          />
          <path stroke="#96a29c" vector-effect="non-scaling-stroke" d="
            M 983.16 428.31
            C 993.41 422.97 996.20 413.65 993.24 402.55
            Q 991.71 396.82 987.03 390.22
            C 983.54 385.28 976.59 381.60 970.84 380.09
            A 2.22 2.20 -63.2 0 0 968.56 380.85
            C 965.59 384.57 962.37 388.19 960.22 392.42
            C 955.72 401.30 950.34 420.65 960.53 427.96
            C 966.95 432.57 976.73 431.65 983.16 428.31"
          />
          <path stroke="#97a29c" vector-effect="non-scaling-stroke" d="
            M 986.94 663.23
            C 993.87 657.18 994.71 646.00 990.40 638.45
            C 987.72 633.75 984.46 630.20 978.88 629.37
            Q 973.36 628.55 968.21 629.70
            A 1.13 1.13 0.0 0 0 967.33 630.80
            L 967.40 663.78
            A 1.29 1.28 7.3 0 0 968.37 665.02
            C 973.24 666.26 982.68 666.95 986.94 663.23"
          />
          <path stroke="#97a29c" vector-effect="non-scaling-stroke" d="
            M 210.59 647.70
            A 0.54 0.54 0.0 0 0 211.07 648.46
            L 219.81 648.58
            A 0.54 0.54 0.0 0 0 220.33 647.87
            L 216.58 636.62
            A 0.54 0.54 0.0 0 0 215.57 636.57
            L 210.59 647.70"
          />
          <path stroke="#97a29c" vector-effect="non-scaling-stroke" d="
            M 355.58 665.31
            C 368.23 660.14 367.08 631.27 352.26 629.23
            C 348.51 628.71 343.41 628.97 340.44 631.67
            C 332.94 638.49 332.22 653.62 338.28 662.16
            Q 339.53 663.91 341.58 664.81
            Q 348.92 668.03 355.58 665.31"
          />
          <path stroke="#97a29c" vector-effect="non-scaling-stroke" d="
            M 608.09 647.56
            A 0.60 0.60 0.0 0 0 608.62 648.42
            L 617.52 648.60
            A 0.60 0.60 0.0 0 0 618.11 647.82
            L 614.55 636.61
            A 0.60 0.60 0.0 0 0 613.44 636.52
            L 608.09 647.56"
          />
          <path stroke="#97a29c" vector-effect="non-scaling-stroke" d="
            M 1098.97 646.96
            A 1.05 1.05 0.0 0 0 1099.92 648.40
            L 1107.52 648.62
            A 1.05 1.05 0.0 0 0 1108.54 647.25
            L 1105.05 636.67
            A 1.05 1.05 0.0 0 0 1103.08 636.61
            L 1098.97 646.96"
          />
      </g>


      <path fill="none" d="
        M 0.00 0.00
        L 1578.00 0.00
        L 1578.00 731.00
        L 0.00 731.00
        L 0.00 0.00
        Z
        M 556.69 396.82
        Q 558.05 396.71 557.49 397.95
        Q 551.46 411.09 540.21 421.41
        C 524.38 435.92 507.34 448.23 489.19 460.29
        Q 486.36 462.17 483.15 463.78
        C 480.36 465.18 481.61 468.97 482.83 470.87
        A 1.08 1.07 64.6 0 0 484.06 471.32
        Q 488.77 469.79 493.33 468.36
        C 500.36 466.15 507.08 463.51 514.10 461.25
        C 522.66 458.50 530.69 454.55 538.87 451.17
        Q 544.98 448.65 548.04 444.85
        C 552.28 439.58 556.64 433.39 559.64 427.15
        Q 562.83 420.50 565.33 414.26
        Q 568.87 405.45 570.55 393.46
        A 2.81 2.80 -6.8 0 1 572.32 391.22
        Q 579.41 388.44 585.22 384.21
        Q 594.13 377.73 602.88 368.92
        Q 611.75 360.00 619.54 352.46
        C 622.39 349.70 628.02 344.65 632.26 345.28
        C 638.77 346.26 644.51 349.64 650.35 351.95
        C 659.19 355.46 666.10 350.67 670.88 343.54
        Q 677.33 333.92 680.13 323.28
        C 681.18 319.28 679.71 315.28 676.39 312.95
        Q 670.53 308.84 662.92 304.56
        Q 657.41 301.46 657.66 296.11
        Q 658.03 288.19 663.71 281.43
        C 669.71 274.29 679.51 271.30 688.88 271.10
        Q 694.13 271.00 698.02 273.82
        C 702.46 277.05 707.18 280.51 712.79 280.96
        Q 717.85 281.36 721.33 280.21
        Q 726.59 278.49 731.76 274.17
        C 736.13 270.53 740.77 264.12 743.35 259.16
        Q 747.14 251.88 751.19 242.15
        C 754.23 234.85 756.03 227.22 758.31 219.70
        C 760.58 212.19 762.17 203.86 763.15 196.24
        C 764.99 181.98 766.45 167.76 767.20 153.38
        Q 768.61 126.01 770.19 94.29
        C 770.81 81.94 771.43 69.83 773.23 57.61
        A 1.04 1.04 0.0 0 0 772.62 56.51
        L 768.87 54.81
        Q 767.20 54.05 766.57 55.77
        Q 763.24 64.82 759.54 74.26
        Q 756.51 82.00 754.82 88.93
        C 752.17 99.80 749.70 110.79 748.33 121.93
        Q 747.34 129.90 747.16 139.41
        C 746.71 164.09 745.82 189.44 744.16 214.00
        C 743.43 224.81 742.03 234.04 737.13 243.50
        Q 734.64 248.31 730.36 249.69
        C 725.31 251.32 720.39 251.27 716.24 247.98
        C 712.21 244.77 708.36 241.82 703.02 241.22
        C 676.21 238.21 654.29 273.33 644.78 294.26
        Q 642.01 300.35 640.14 309.24
        C 639.98 310.02 639.58 311.25 638.96 311.76
        Q 631.69 317.78 626.86 324.41
        Q 621.02 332.41 615.04 340.78
        Q 604.37 355.71 588.84 364.07
        C 583.62 366.88 579.09 368.02 573.80 369.63
        Q 572.70 369.97 572.61 368.81
        C 571.90 359.45 571.22 350.03 568.19 341.40
        C 563.27 327.38 548.22 320.88 535.69 330.18
        Q 532.36 332.65 529.23 338.39
        Q 525.25 345.69 520.59 355.46
        C 516.55 363.96 513.94 369.63 513.74 378.13
        C 513.52 387.68 520.72 395.69 529.74 397.47
        C 538.78 399.24 547.62 397.57 556.69 396.82
        Z
        M 666.68 161.21
        Q 674.66 168.50 682.79 176.08
        Q 684.63 177.79 686.73 176.42
        Q 696.41 170.12 704.24 160.89
        C 709.03 155.23 710.48 147.76 705.41 141.60
        C 700.91 136.14 694.13 131.60 688.19 127.50
        C 686.35 126.22 684.36 125.97 682.59 127.62
        Q 672.69 136.83 664.81 146.62
        A 0.89 0.88 46.9 0 1 663.39 146.57
        C 659.12 140.40 651.90 135.89 645.30 132.05
        C 643.93 131.25 642.72 131.63 641.51 132.53
        Q 638.89 134.46 637.42 136.01
        Q 627.00 146.99 618.32 158.06
        C 617.43 159.20 617.29 160.75 618.51 161.72
        Q 630.47 171.27 640.57 181.36
        C 642.66 183.45 645.38 181.27 647.25 179.79
        Q 651.72 176.24 655.88 172.66
        C 659.78 169.29 662.79 165.52 665.68 161.33
        A 0.67 0.66 -51.4 0 1 666.68 161.21
        Z
        M 1279.88 196.78
        C 1283.90 193.07 1291.23 184.54 1289.15 178.79
        C 1285.45 168.56 1276.95 163.82 1268.56 157.87
        Q 1265.93 156.01 1263.46 158.34
        Q 1252.36 168.77 1241.56 182.77
        C 1240.39 184.29 1239.14 186.05 1241.25 187.65
        Q 1252.09 195.90 1261.99 206.01
        C 1263.57 207.62 1264.88 208.59 1266.83 207.20
        Q 1274.52 201.73 1279.88 196.78
        Z
        M 1176.08 188.39
        Q 1175.72 188.27 1175.53 187.98
        C 1173.23 184.43 1170.62 182.53 1167.45 180.08
        Q 1162.46 176.23 1157.59 173.25
        Q 1156.57 172.62 1155.49 172.81
        C 1153.59 173.15 1150.66 176.28 1149.27 177.71
        Q 1138.80 188.51 1130.88 199.11
        Q 1128.97 201.67 1131.41 203.65
        Q 1141.98 212.24 1151.21 221.25
        C 1155.34 225.29 1159.05 221.27 1162.34 218.79
        Q 1171.50 211.86 1178.11 202.46
        A 0.64 0.64 0.0 0 1 1179.13 202.41
        Q 1182.09 205.93 1185.62 208.88
        C 1189.08 211.77 1192.14 215.02 1195.44 218.06
        Q 1196.95 219.45 1198.60 218.23
        Q 1205.62 213.07 1211.40 207.65
        C 1219.32 200.23 1225.20 190.46 1215.63 180.92
        Q 1208.09 173.40 1198.21 168.01
        C 1197.25 167.48 1196.15 167.88 1195.39 168.55
        Q 1185.20 177.55 1176.88 188.14
        A 0.74 0.73 27.3 0 1 1176.08 188.39
        Z
        M 838.51 422.74
        C 841.38 415.94 844.57 409.08 846.63 402.01
        Q 849.48 392.20 852.11 382.47
        C 855.53 369.78 857.16 356.07 857.87 342.02
        C 858.82 323.08 859.85 304.95 861.30 286.52
        C 862.82 267.14 863.71 244.93 866.07 224.19
        Q 866.79 217.85 868.25 212.01
        Q 868.57 210.72 868.36 209.42
        Q 868.26 208.82 867.69 208.64
        L 864.71 207.69
        Q 863.57 207.33 863.01 208.38
        Q 859.02 215.83 856.22 223.47
        C 853.81 230.08 850.75 236.35 848.48 243.01
        Q 840.58 266.30 839.79 292.00
        Q 838.64 329.97 836.68 379.14
        Q 835.79 401.55 832.22 421.24
        Q 832.05 422.22 832.98 422.56
        Q 835.23 423.38 837.60 423.35
        A 1.01 0.99 10.7 0 0 838.51 422.74
        Z
        M 981.58 269.42
        Q 986.58 273.95 991.52 278.37
        C 993.90 280.51 996.08 283.25 998.75 285.13
        Q 1000.19 286.15 1001.68 285.12
        Q 1009.03 280.01 1015.68 273.36
        C 1023.35 265.68 1027.77 256.88 1018.80 248.01
        C 1013.62 242.89 1008.09 238.78 1001.94 235.37
        C 1000.36 234.49 998.61 235.31 997.41 236.40
        Q 988.48 244.49 979.89 254.97
        A 0.91 0.90 49.5 0 1 978.41 254.85
        Q 976.63 251.74 973.67 249.45
        Q 966.63 244.00 960.15 239.98
        Q 958.78 239.13 957.43 240.15
        Q 954.91 242.03 952.77 244.21
        Q 942.45 254.70 933.49 266.61
        Q 931.91 268.71 933.91 270.30
        Q 945.34 279.41 956.08 289.95
        A 2.40 2.39 50.5 0 0 959.07 290.24
        C 966.98 285.04 976.26 277.79 980.53 269.61
        Q 980.93 268.84 981.58 269.42
        Z
        M 1101.47 396.61
        Q 1103.33 396.33 1102.64 398.07
        Q 1099.29 406.52 1094.12 412.61
        Q 1086.67 421.37 1075.50 430.50
        Q 1055.81 446.58 1034.55 460.38
        Q 1031.45 462.39 1027.97 464.34
        C 1025.89 465.50 1027.06 469.27 1027.94 470.86
        A 1.21 1.20 -22.9 0 0 1029.36 471.43
        Q 1045.19 466.36 1060.93 460.82
        C 1069.44 457.81 1077.69 454.08 1085.78 450.55
        C 1093.86 447.01 1098.64 438.45 1103.18 431.10
        C 1106.20 426.20 1108.00 421.05 1110.18 415.82
        Q 1114.45 405.59 1116.22 394.60
        A 1.16 1.16 0.0 0 1 1117.10 393.65
        Q 1127.60 391.15 1137.34 388.19
        Q 1144.61 385.97 1149.31 383.53
        C 1155.39 380.38 1161.76 377.32 1167.48 373.69
        Q 1174.77 369.07 1182.01 364.37
        A 3.80 3.71 28.8 0 1 1184.20 363.76
        Q 1189.30 363.90 1194.24 363.86
        Q 1196.23 363.84 1198.18 364.63
        C 1204.67 367.26 1216.37 363.08 1222.60 360.29
        C 1233.19 355.55 1242.87 345.57 1249.26 335.77
        Q 1253.81 328.82 1255.87 322.36
        Q 1258.65 313.66 1259.87 305.99
        Q 1262.54 289.14 1264.05 269.10
        C 1264.25 266.48 1264.83 263.64 1265.31 261.02
        A 1.69 1.68 20.4 0 0 1264.49 259.25
        C 1262.92 258.34 1259.82 256.64 1258.52 258.80
        Q 1255.30 264.14 1252.27 268.95
        C 1247.86 275.94 1245.57 283.58 1245.31 291.49
        Q 1245.02 300.15 1244.69 309.40
        C 1244.36 318.62 1242.28 327.99 1233.98 333.04
        C 1227.18 337.17 1217.86 342.02 1209.68 339.59
        A 0.43 0.43 0.0 0 1 1209.41 338.99
        Q 1211.13 335.39 1212.98 331.78
        C 1217.74 322.52 1219.17 311.75 1218.59 300.96
        C 1218.11 292.26 1214.44 284.82 1208.32 278.80
        C 1203.02 273.59 1194.08 272.36 1186.65 273.10
        Q 1175.56 274.20 1167.01 282.27
        C 1157.03 291.69 1151.90 302.34 1147.82 315.19
        C 1145.26 323.26 1144.02 336.02 1148.20 344.05
        Q 1151.31 350.05 1156.84 355.18
        A 1.34 1.33 -36.0 0 1 1156.49 357.37
        Q 1147.02 361.75 1137.77 365.49
        Q 1131.94 367.86 1118.97 369.84
        Q 1118.34 369.93 1118.31 369.30
        C 1117.36 353.56 1116.25 329.27 1096.25 326.02
        C 1089.80 324.97 1082.24 328.07 1078.28 333.23
        Q 1074.71 337.88 1072.36 342.88
        Q 1068.72 350.66 1064.39 359.07
        Q 1060.63 366.38 1059.67 375.44
        C 1058.54 386.23 1064.44 395.08 1075.16 397.40
        Q 1081.08 398.68 1087.29 398.21
        Q 1094.63 397.66 1101.47 396.61
        Z
        M 438.40 369.05
        Q 435.54 375.98 430.30 384.89
        C 426.73 390.96 422.49 395.98 417.69 400.71
        Q 390.70 427.38 359.82 449.30
        Q 357.73 450.78 350.55 454.80
        Q 349.47 455.40 348.77 456.42
        A 1.29 1.23 59.5 0 0 348.56 457.32
        Q 348.88 459.58 349.79 461.43
        A 1.32 1.31 -19.1 0 0 351.25 462.13
        C 358.31 460.59 365.34 459.09 372.26 457.08
        Q 382.02 454.25 391.06 451.97
        Q 396.09 450.71 400.92 448.90
        C 406.22 446.92 412.35 444.57 416.26 440.35
        Q 423.14 432.92 428.33 423.94
        Q 431.09 419.18 434.02 413.85
        C 437.64 407.27 440.15 400.35 443.14 393.74
        C 447.13 384.92 449.67 375.50 452.70 366.50
        C 456.08 356.47 458.52 345.91 460.91 335.65
        Q 461.12 334.70 460.18 334.50
        L 454.00 333.16
        A 1.26 1.26 0.0 0 0 452.54 333.96
        Q 449.59 341.93 446.55 350.33
        C 444.28 356.59 441.05 362.66 438.40 369.05
        Z
        M 946.18 449.87
        C 952.70 454.87 962.60 454.28 970.22 452.55
        C 983.89 449.45 996.32 437.83 1003.28 426.24
        Q 1007.66 418.96 1011.09 409.21
        C 1015.01 398.09 1015.43 382.87 1013.08 371.45
        C 1009.93 356.18 998.95 341.86 983.01 338.62
        C 975.42 337.07 970.95 343.33 967.34 348.96
        C 962.84 355.96 958.75 362.84 957.94 370.81
        C 957.73 372.96 959.62 375.14 957.90 377.04
        Q 950.17 385.61 943.70 399.29
        Q 938.89 409.48 937.63 424.05
        C 936.75 434.38 937.76 443.42 946.18 449.87
        Z
        M 78.14 636.35
        Q 79.23 636.62 79.96 637.41
        Q 93.06 651.55 104.42 667.00
        C 105.34 668.26 106.84 668.56 108.30 668.46
        Q 109.48 668.38 109.48 667.21
        Q 109.48 657.77 109.17 648.34
        Q 108.88 639.28 109.83 632.37
        C 110.16 629.99 113.86 629.43 115.72 628.70
        Q 116.56 628.37 116.37 628.05
        A 1.60 1.52 -15.6 0 0 114.97 627.31
        Q 107.55 627.53 99.85 627.38
        A 0.85 0.85 0.0 0 0 99.02 628.00
        Q 98.86 628.61 99.47 628.82
        Q 102.01 629.70 104.44 630.62
        A 1.98 1.95 -85.7 0 1 105.66 632.06
        C 107.10 639.24 106.79 647.18 106.85 654.98
        Q 106.86 655.54 106.58 656.02
        A 0.50 0.49 43.5 0 1 105.74 656.04
        Q 103.45 652.54 101.20 649.95
        Q 91.02 638.26 82.68 628.15
        A 2.18 2.17 -20.0 0 0 80.98 627.36
        L 67.45 627.54
        A 0.74 0.74 0.0 0 0 66.74 628.45
        Q 66.89 629.05 67.75 629.24
        C 70.60 629.89 75.56 630.91 75.52 634.85
        Q 75.38 646.57 75.18 658.58
        Q 75.12 661.76 74.04 664.62
        A 1.05 1.05 0.0 0 1 73.33 665.26
        L 66.76 666.99
        Q 66.55 667.04 66.57 667.26
        L 66.59 667.52
        Q 66.63 668.12 67.24 668.13
        Q 75.39 668.29 85.07 668.21
        Q 85.43 668.20 85.71 667.92
        Q 86.54 667.07 85.38 666.81
        Q 83.02 666.28 80.84 665.60
        C 77.95 664.72 77.87 659.92 77.86 657.51
        Q 77.78 647.41 77.84 636.59
        A 0.25 0.24 -83.5 0 1 78.14 636.35
        Z
        M 502.20 656.98
        Q 501.78 658.30 501.16 657.06
        Q 495.17 644.95 492.14 631.77
        A 1.60 1.60 0.0 0 1 493.02 629.96
        Q 494.39 629.31 496.00 628.98
        Q 496.75 628.83 496.90 628.15
        A 0.70 0.69 -84.5 0 0 496.20 627.30
        Q 487.81 627.54 479.24 627.39
        Q 478.40 627.38 477.83 627.77
        A 0.54 0.54 0.0 0 0 477.98 628.74
        Q 482.17 629.99 483.51 633.20
        C 484.80 636.31 487.02 640.08 485.39 643.41
        Q 484.50 645.22 484.00 647.25
        Q 482.71 652.62 479.45 657.20
        A 0.41 0.40 -35.1 0 1 478.73 657.08
        Q 475.25 644.24 470.29 632.71
        C 468.93 629.54 472.33 629.10 474.39 628.57
        Q 475.21 628.37 474.56 627.83
        Q 474.05 627.42 473.38 627.42
        Q 465.23 627.46 457.16 627.53
        Q 456.65 627.53 456.42 627.98
        Q 456.13 628.56 456.75 628.75
        Q 460.55 629.93 461.81 632.72
        Q 464.29 638.22 467.08 646.98
        Q 469.36 654.12 472.61 661.83
        Q 473.37 663.62 473.98 665.57
        Q 475.79 671.36 478.21 665.80
        Q 480.12 661.40 482.09 657.36
        Q 485.08 651.23 486.44 645.10
        A 0.69 0.68 28.9 0 1 487.60 644.77
        Q 488.20 645.38 488.51 646.14
        Q 492.58 656.06 495.96 666.84
        C 496.38 668.15 497.61 668.83 498.92 668.31
        Q 499.33 668.15 499.53 667.75
        Q 502.45 661.75 504.46 655.60
        Q 506.13 650.51 508.27 646.48
        Q 511.51 640.40 513.93 633.92
        C 515.00 631.02 517.30 629.85 520.05 628.98
        Q 520.56 628.82 520.83 628.35
        Q 521.14 627.82 520.06 627.42
        A 1.42 1.20 49.7 0 0 519.64 627.35
        Q 513.14 627.40 507.10 627.36
        Q 506.41 627.35 505.97 627.87
        A 0.70 0.69 -60.2 0 0 506.27 628.98
        L 510.32 630.35
        A 1.37 1.36 -79.2 0 1 511.25 631.73
        Q 511.14 633.80 510.42 635.75
        Q 508.30 641.50 505.48 647.66
        C 504.10 650.68 503.19 653.83 502.20 656.98
        Z
        M 736.48 643.77
        L 736.74 632.40
        Q 736.76 631.69 737.22 631.16
        C 738.57 629.61 741.22 629.19 743.13 628.72
        A 0.53 0.52 -25.2 0 0 743.43 627.90
        Q 743.00 627.33 742.29 627.35
        Q 732.33 627.55 723.11 627.38
        A 0.97 0.97 0.0 0 0 722.22 627.93
        Q 721.90 628.59 722.62 628.84
        Q 724.49 629.50 726.28 630.10
        A 2.53 2.53 0.0 0 1 727.99 632.48
        Q 728.10 647.67 727.91 662.25
        Q 727.89 663.71 727.27 664.81
        Q 726.99 665.30 726.45 665.45
        L 721.72 666.77
        A 0.47 0.47 0.0 0 0 721.46 667.49
        Q 721.80 668.00 722.40 668.04
        Q 732.29 668.76 743.04 668.04
        A 0.74 0.73 -82.2 0 0 743.69 667.54
        Q 743.93 666.81 743.18 666.65
        Q 740.73 666.09 738.45 665.46
        A 1.72 1.71 -12.4 0 1 737.57 664.90
        Q 736.48 663.60 736.51 660.76
        Q 736.60 654.63 736.56 647.89
        A 0.57 0.57 0.0 0 1 737.10 647.32
        Q 746.70 646.79 756.44 647.15
        A 0.93 0.93 0.0 0 1 757.34 648.06
        Q 757.48 654.89 757.20 662.37
        C 757.07 666.02 753.74 665.96 751.13 666.62
        Q 750.56 666.76 750.85 667.47
        A 0.73 0.73 0.0 0 0 751.46 667.92
        Q 761.92 668.80 772.28 668.01
        Q 772.78 667.98 773.00 667.63
        A 0.64 0.64 0.0 0 0 772.58 666.66
        C 770.19 666.19 766.05 666.14 765.95 662.68
        Q 765.57 650.57 766.00 632.85
        C 766.08 629.81 769.68 629.27 771.89 628.75
        Q 773.54 628.36 772.03 627.59
        Q 771.45 627.29 770.79 627.30
        Q 761.52 627.45 752.14 627.39
        A 0.96 0.95 -79.2 0 0 751.25 628.00
        Q 751.14 628.30 751.32 628.58
        Q 751.55 628.91 751.95 629.01
        C 755.42 629.87 757.20 630.05 757.27 634.26
        Q 757.37 639.64 757.29 644.35
        A 0.81 0.81 0.0 0 1 756.49 645.14
        L 737.87 645.19
        Q 736.44 645.19 736.48 643.77
        Z
        M 957.82 630.35
        A 1.57 1.56 -77.6 0 1 958.72 631.77
        Q 958.80 646.84 958.70 661.50
        C 958.67 666.29 955.79 665.41 952.54 666.58
        Q 952.21 666.70 952.08 667.03
        A 0.71 0.71 0.0 0 0 952.70 668.00
        C 956.42 668.20 960.22 668.52 963.95 668.43
        Q 973.65 668.18 983.98 667.91
        Q 996.99 667.56 1001.21 654.59
        Q 1003.41 647.82 1000.01 640.69
        Q 993.92 627.91 979.61 627.58
        Q 968.12 627.31 953.14 627.47
        A 0.83 0.83 0.0 0 0 952.33 628.21
        L 952.30 628.51
        A 0.45 0.45 0.0 0 0 952.70 629.00
        Q 955.65 629.34 957.82 630.35
        Z
        M 1220.95 631.11
        Q 1220.75 646.40 1220.72 662.17
        Q 1220.72 663.89 1219.91 665.51
        Q 1219.65 666.04 1219.07 666.14
        L 1215.05 666.87
        Q 1214.62 666.95 1214.57 667.38
        L 1214.53 667.70
        A 0.24 0.24 0.0 0 0 1214.75 667.97
        Q 1223.91 668.87 1235.60 668.05
        Q 1236.08 668.01 1236.32 667.64
        A 0.43 0.42 -68.3 0 0 1236.05 667.00
        L 1231.21 665.96
        A 1.86 1.85 -87.8 0 1 1229.75 664.38
        Q 1229.40 661.59 1229.42 658.50
        Q 1229.49 644.44 1229.33 630.96
        A 1.69 1.68 -89.0 0 1 1231.09 629.25
        Q 1235.37 629.44 1238.80 630.90
        C 1240.61 631.68 1242.59 637.35 1243.23 639.29
        A 0.59 0.58 50.1 0 0 1244.30 639.39
        Q 1244.64 638.76 1244.56 638.01
        Q 1243.96 632.93 1244.13 628.26
        A 0.75 0.75 0.0 0 0 1243.39 627.49
        L 1207.25 627.36
        A 0.83 0.83 0.0 0 0 1206.41 628.20
        Q 1206.45 633.15 1206.18 638.42
        A 1.29 1.04 -57.4 0 0 1206.21 638.69
        Q 1206.43 639.50 1206.92 639.51
        Q 1207.53 639.53 1207.73 638.95
        C 1208.85 635.62 1209.37 631.54 1213.12 630.25
        Q 1216.00 629.25 1219.29 629.36
        A 1.73 1.72 -88.6 0 1 1220.95 631.11
        Z
        M 206.73 665.05
        Q 204.94 664.71 205.36 662.93
        Q 206.60 657.70 208.87 651.76
        Q 209.37 650.44 210.79 650.47
        L 220.96 650.71
        A 1.36 1.35 -9.0 0 1 222.21 651.61
        Q 223.95 656.49 225.59 661.73
        C 227.02 666.30 222.73 665.57 220.15 666.39
        Q 219.55 666.58 219.60 667.23
        A 0.94 0.94 0.0 0 0 220.48 668.09
        Q 230.73 668.81 241.11 668.10
        Q 241.52 668.07 241.82 667.83
        Q 242.15 667.57 242.07 667.16
        A 0.89 0.88 88.2 0 0 241.31 666.44
        Q 238.58 666.11 236.21 664.69
        A 1.73 1.69 3.4 0 1 235.54 663.95
        C 234.08 660.73 233.24 657.32 231.84 654.04
        Q 226.67 641.87 222.22 628.57
        Q 221.76 627.22 220.39 627.60
        L 215.20 629.02
        Q 214.39 629.24 214.48 630.07
        Q 214.95 634.26 212.86 637.74
        C 209.97 642.53 207.60 647.49 205.74 652.83
        Q 204.51 656.37 202.53 660.44
        Q 200.04 665.55 195.84 666.29
        A 0.85 0.85 0.0 0 0 195.16 667.35
        Q 195.32 667.96 195.97 668.01
        Q 202.29 668.50 208.94 668.17
        Q 211.85 668.02 209.53 666.26
        Q 208.33 665.35 206.73 665.05
        Z
        M 353.17 627.57
        Q 346.22 627.10 341.17 628.15
        C 333.80 629.67 327.33 635.18 325.73 642.54
        C 323.11 654.57 328.67 665.42 341.49 667.57
        Q 350.24 669.03 359.90 666.82
        Q 364.98 665.66 369.26 660.53
        Q 373.99 654.85 373.38 646.11
        C 372.63 635.44 363.42 628.27 353.17 627.57
        Z
        M 603.44 663.93
        Q 603.29 662.40 603.77 660.94
        Q 605.31 656.34 606.81 651.65
        A 1.51 1.50 8.5 0 1 608.23 650.61
        L 618.24 650.53
        A 1.88 1.87 -10.9 0 1 620.00 651.72
        Q 621.62 655.89 623.24 660.35
        Q 624.03 662.51 623.63 664.64
        Q 623.52 665.22 622.93 665.34
        L 617.96 666.31
        A 0.75 0.75 0.0 0 0 617.47 667.46
        Q 617.81 667.99 618.47 668.04
        Q 629.17 668.85 639.03 668.12
        Q 639.59 668.08 639.73 667.54
        Q 639.81 667.24 639.71 666.95
        A 0.91 0.90 -5.0 0 0 639.01 666.37
        C 636.80 666.00 634.02 665.45 633.17 663.08
        C 630.55 655.71 627.04 648.50 624.54 640.91
        Q 622.43 634.49 619.69 628.35
        A 1.34 1.32 -18.9 0 0 618.14 627.60
        L 613.25 628.84
        Q 612.65 629.00 612.65 629.62
        C 612.68 631.64 612.77 633.19 611.76 635.08
        C 608.89 640.47 606.32 645.56 604.29 651.53
        Q 602.31 657.34 597.95 663.97
        C 596.79 665.73 593.44 665.72 593.03 667.00
        A 0.78 0.78 0.0 0 0 593.71 668.02
        Q 600.39 668.56 607.56 668.08
        Q 608.00 668.05 608.34 667.76
        Q 608.66 667.49 608.55 667.06
        Q 608.39 666.42 607.78 666.19
        L 604.15 664.84
        Q 603.51 664.61 603.44 663.93
        Z
        M 1113.42 665.28
        L 1108.05 666.56
        A 0.55 0.55 0.0 0 0 1107.84 667.52
        Q 1108.32 667.90 1108.98 667.96
        Q 1119.00 668.89 1129.07 668.12
        Q 1129.77 668.07 1129.73 667.36
        L 1129.70 666.94
        A 0.78 0.76 1.4 0 0 1129.01 666.23
        C 1127.11 666.04 1124.76 665.34 1123.90 663.43
        Q 1118.98 652.51 1115.32 641.58
        Q 1112.96 634.55 1109.66 628.01
        A 0.92 0.91 -20.6 0 0 1108.61 627.54
        L 1103.50 628.93
        A 0.87 0.86 82.9 0 0 1102.87 629.75
        C 1102.86 631.51 1102.91 633.14 1102.06 634.78
        Q 1098.75 641.18 1096.06 648.49
        C 1094.39 653.01 1091.82 657.18 1089.95 661.60
        Q 1088.37 665.34 1083.92 666.30
        A 1.20 1.15 -74.0 0 0 1083.35 666.63
        Q 1082.89 667.12 1083.14 667.53
        Q 1083.41 667.98 1083.94 668.02
        Q 1091.29 668.58 1098.47 668.04
        A 0.86 0.86 0.0 0 0 1099.27 667.18
        Q 1099.27 666.71 1098.79 666.53
        Q 1096.71 665.77 1094.81 664.87
        A 2.20 2.19 -67.4 0 1 1093.69 662.13
        L 1097.62 651.51
        A 1.31 1.30 -80.2 0 1 1098.83 650.65
        L 1108.26 650.52
        A 2.11 2.10 -12.2 0 1 1110.20 651.77
        Q 1112.43 656.77 1114.05 661.20
        Q 1114.59 662.68 1114.30 664.34
        Q 1114.17 665.10 1113.42 665.28
        Z
        M 1351.33 668.12
        L 1364.68 668.21
        A 2.37 2.36 -81.5 0 0 1366.96 666.52
        Q 1368.36 661.81 1370.77 656.39
        A 0.88 0.87 -59.4 0 0 1370.50 655.33
        L 1370.28 655.16
        A 0.40 0.40 0.0 0 0 1369.69 655.29
        C 1367.04 660.18 1364.96 663.30 1359.74 664.98
        Q 1351.72 667.56 1343.99 666.18
        C 1343.45 665.96 1343.03 665.38 1343.02 664.79
        Q 1342.91 656.55 1342.87 648.08
        A 0.99 0.98 -2.5 0 1 1343.77 647.10
        Q 1346.53 646.88 1349.17 647.31
        Q 1355.14 648.29 1356.67 654.41
        Q 1356.82 655.02 1357.44 655.12
        L 1357.77 655.18
        Q 1358.33 655.28 1358.24 654.71
        Q 1356.86 646.54 1358.14 639.00
        Q 1358.22 638.49 1357.94 638.08
        A 0.69 0.69 0.0 0 0 1356.75 638.19
        C 1354.26 643.65 1350.31 645.35 1344.53 645.46
        A 1.44 1.44 0.0 0 1 1343.07 644.04
        L 1342.85 630.96
        A 1.68 1.67 -2.9 0 1 1344.39 629.26
        Q 1350.77 628.71 1356.25 629.96
        C 1360.46 630.92 1362.85 634.34 1364.59 637.96
        Q 1364.90 638.60 1365.59 638.50
        Q 1366.18 638.41 1366.10 637.82
        Q 1365.49 632.97 1365.76 628.32
        A 0.78 0.78 0.0 0 0 1364.98 627.49
        Q 1347.12 627.47 1329.25 627.50
        Q 1328.68 627.50 1328.47 628.05
        Q 1328.27 628.58 1328.79 628.79
        C 1331.62 629.91 1334.56 630.32 1334.65 634.17
        Q 1334.99 648.26 1334.67 661.22
        C 1334.55 665.75 1331.74 665.70 1328.38 666.69
        A 0.50 0.50 0.0 0 0 1328.23 667.57
        Q 1328.59 667.83 1329.26 667.87
        Q 1340.21 668.61 1351.33 668.12
        Z
        M 1479.17 661.58
        Q 1478.96 664.61 1476.43 665.58
        C 1471.04 667.64 1463.03 666.32 1459.25 661.58
        Q 1456.41 658.00 1454.29 654.56
        A 0.46 0.45 47.8 0 0 1453.54 654.52
        Q 1453.16 655.00 1453.31 655.60
        Q 1454.83 661.56 1455.57 667.25
        A 0.63 0.62 -7.1 0 0 1456.26 667.78
        C 1459.87 667.33 1463.29 667.43 1466.87 667.90
        Q 1472.98 668.69 1479.18 667.11
        C 1485.21 665.59 1487.92 659.39 1486.06 653.61
        C 1484.83 649.75 1480.70 646.74 1477.01 645.10
        Q 1470.23 642.08 1465.46 639.36
        Q 1461.02 636.83 1462.83 631.96
        C 1464.29 628.02 1471.98 628.94 1474.60 630.13
        C 1478.28 631.80 1481.18 636.10 1482.70 639.86
        A 0.79 0.78 81.6 0 0 1483.34 640.34
        Q 1484.09 640.41 1484.22 639.96
        Q 1484.29 639.73 1484.27 639.50
        Q 1483.85 634.75 1484.03 629.96
        A 0.58 0.57 5.0 0 0 1483.53 629.37
        Q 1479.50 628.85 1475.55 628.04
        Q 1470.55 627.01 1464.47 627.65
        Q 1458.58 628.26 1456.10 632.56
        C 1453.09 637.78 1454.83 643.99 1460.12 647.04
        Q 1465.12 649.93 1471.55 652.98
        C 1475.76 654.98 1479.50 656.64 1479.17 661.58
        Z
        M 330.04 673.84
        C 338.08 671.35 345.61 676.20 352.05 680.15
        C 356.78 683.04 364.89 683.85 369.54 679.96
        C 371.50 678.32 373.23 676.41 374.99 674.47
        Q 375.80 673.58 375.60 672.56
        A 0.71 0.71 0.0 0 0 374.42 672.18
        Q 367.79 678.41 357.54 675.55
        Q 351.19 673.77 345.55 672.17
        Q 338.63 670.22 331.22 671.87
        Q 330.05 672.13 329.40 672.67
        Q 326.84 674.83 330.04 673.84
        Z"
      />
      <path class="logopathfont" d="
        M 556.69 396.82
        C 547.62 397.57 538.78 399.24 529.74 397.47
        C 520.72 395.69 513.52 387.68 513.74 378.13
        C 513.94 369.63 516.55 363.96 520.59 355.46
        Q 525.25 345.69 529.23 338.39
        Q 532.36 332.65 535.69 330.18
        C 548.22 320.88 563.27 327.38 568.19 341.40
        C 571.22 350.03 571.90 359.45 572.61 368.81
        Q 572.70 369.97 573.80 369.63
        C 579.09 368.02 583.62 366.88 588.84 364.07
        Q 604.37 355.71 615.04 340.78
        Q 621.02 332.41 626.86 324.41
        Q 631.69 317.78 638.96 311.76
        C 639.58 311.25 639.98 310.02 640.14 309.24
        Q 642.01 300.35 644.78 294.26
        C 654.29 273.33 676.21 238.21 703.02 241.22
        C 708.36 241.82 712.21 244.77 716.24 247.98
        C 720.39 251.27 725.31 251.32 730.36 249.69
        Q 734.64 248.31 737.13 243.50
        C 742.03 234.04 743.43 224.81 744.16 214.00
        C 745.82 189.44 746.71 164.09 747.16 139.41
        Q 747.34 129.90 748.33 121.93
        C 749.70 110.79 752.17 99.80 754.82 88.93
        Q 756.51 82.00 759.54 74.26
        Q 763.24 64.82 766.57 55.77
        Q 767.20 54.05 768.87 54.81
        L 772.62 56.51
        A 1.04 1.04 0.0 0 1 773.23 57.61
        C 771.43 69.83 770.81 81.94 770.19 94.29
        Q 768.61 126.01 767.20 153.38
        C 766.45 167.76 764.99 181.98 763.15 196.24
        C 762.17 203.86 760.58 212.19 758.31 219.70
        C 756.03 227.22 754.23 234.85 751.19 242.15
        Q 747.14 251.88 743.35 259.16
        C 740.77 264.12 736.13 270.53 731.76 274.17
        Q 726.59 278.49 721.33 280.21
        Q 717.85 281.36 712.79 280.96
        C 707.18 280.51 702.46 277.05 698.02 273.82
        Q 694.13 271.00 688.88 271.10
        C 679.51 271.30 669.71 274.29 663.71 281.43
        Q 658.03 288.19 657.66 296.11
        Q 657.41 301.46 662.92 304.56
        Q 670.53 308.84 676.39 312.95
        C 679.71 315.28 681.18 319.28 680.13 323.28
        Q 677.33 333.92 670.88 343.54
        C 666.10 350.67 659.19 355.46 650.35 351.95
        C 644.51 349.64 638.77 346.26 632.26 345.28
        C 628.02 344.65 622.39 349.70 619.54 352.46
        Q 611.75 360.00 602.88 368.92
        Q 594.13 377.73 585.22 384.21
        Q 579.41 388.44 572.32 391.22
        A 2.81 2.80 -6.8 0 0 570.55 393.46
        Q 568.87 405.45 565.33 414.26
        Q 562.83 420.50 559.64 427.15
        C 556.64 433.39 552.28 439.58 548.04 444.85
        Q 544.98 448.65 538.87 451.17
        C 530.69 454.55 522.66 458.50 514.10 461.25
        C 507.08 463.51 500.36 466.15 493.33 468.36
        Q 488.77 469.79 484.06 471.32
        A 1.08 1.07 64.6 0 1 482.83 470.87
        C 481.61 468.97 480.36 465.18 483.15 463.78
        Q 486.36 462.17 489.19 460.29
        C 507.34 448.23 524.38 435.92 540.21 421.41
        Q 551.46 411.09 557.49 397.95
        Q 558.05 396.71 556.69 396.82
        Z"
      />
      <path class="logopathfont" d="
        M 666.68 161.21
        A 0.67 0.66 -51.4 0 0 665.68 161.33
        C 662.79 165.52 659.78 169.29 655.88 172.66
        Q 651.72 176.24 647.25 179.79
        C 645.38 181.27 642.66 183.45 640.57 181.36
        Q 630.47 171.27 618.51 161.72
        C 617.29 160.75 617.43 159.20 618.32 158.06
        Q 627.00 146.99 637.42 136.01
        Q 638.89 134.46 641.51 132.53
        C 642.72 131.63 643.93 131.25 645.30 132.05
        C 651.90 135.89 659.12 140.40 663.39 146.57
        A 0.89 0.88 46.9 0 0 664.81 146.62
        Q 672.69 136.83 682.59 127.62
        C 684.36 125.97 686.35 126.22 688.19 127.50
        C 694.13 131.60 700.91 136.14 705.41 141.60
        C 710.48 147.76 709.03 155.23 704.24 160.89
        Q 696.41 170.12 686.73 176.42
        Q 684.63 177.79 682.79 176.08
        Q 674.66 168.50 666.68 161.21
        Z"
      />
      <path class="logopathfont" d="
        M 1289.15 178.79
        C 1291.23 184.54 1283.90 193.07 1279.88 196.78
        Q 1274.52 201.73 1266.83 207.20
        C 1264.88 208.59 1263.57 207.62 1261.99 206.01
        Q 1252.09 195.90 1241.25 187.65
        C 1239.14 186.05 1240.39 184.29 1241.56 182.77
        Q 1252.36 168.77 1263.46 158.34
        Q 1265.93 156.01 1268.56 157.87
        C 1276.95 163.82 1285.45 168.56 1289.15 178.79
        Z"
      />
      <path class="logopathfont" d="
        M 1176.08 188.39
        A 0.74 0.73 27.3 0 0 1176.88 188.14
        Q 1185.20 177.55 1195.39 168.55
        C 1196.15 167.88 1197.25 167.48 1198.21 168.01
        Q 1208.09 173.40 1215.63 180.92
        C 1225.20 190.46 1219.32 200.23 1211.40 207.65
        Q 1205.62 213.07 1198.60 218.23
        Q 1196.95 219.45 1195.44 218.06
        C 1192.14 215.02 1189.08 211.77 1185.62 208.88
        Q 1182.09 205.93 1179.13 202.41
        A 0.64 0.64 0.0 0 0 1178.11 202.46
        Q 1171.50 211.86 1162.34 218.79
        C 1159.05 221.27 1155.34 225.29 1151.21 221.25
        Q 1141.98 212.24 1131.41 203.65
        Q 1128.97 201.67 1130.88 199.11
        Q 1138.80 188.51 1149.27 177.71
        C 1150.66 176.28 1153.59 173.15 1155.49 172.81
        Q 1156.57 172.62 1157.59 173.25
        Q 1162.46 176.23 1167.45 180.08
        C 1170.62 182.53 1173.23 184.43 1175.53 187.98
        Q 1175.72 188.27 1176.08 188.39
        Z"
      />
      <path class="logopathfont" d="
        M 846.63 402.01
        C 844.57 409.08 841.38 415.94 838.51 422.74
        A 1.01 0.99 10.7 0 1 837.60 423.35
        Q 835.23 423.38 832.98 422.56
        Q 832.05 422.22 832.22 421.24
        Q 835.79 401.55 836.68 379.14
        Q 838.64 329.97 839.79 292.00
        Q 840.58 266.30 848.48 243.01
        C 850.75 236.35 853.81 230.08 856.22 223.47
        Q 859.02 215.83 863.01 208.38
        Q 863.57 207.33 864.71 207.69
        L 867.69 208.64
        Q 868.26 208.82 868.36 209.42
        Q 868.57 210.72 868.25 212.01
        Q 866.79 217.85 866.07 224.19
        C 863.71 244.93 862.82 267.14 861.30 286.52
        C 859.85 304.95 858.82 323.08 857.87 342.02
        C 857.16 356.07 855.53 369.78 852.11 382.47
        Q 849.48 392.20 846.63 402.01
        Z"
      />
      <path class="logopathfont" d="
        M 980.53 269.61
        C 976.26 277.79 966.98 285.04 959.07 290.24
        A 2.40 2.39 50.5 0 1 956.08 289.95
        Q 945.34 279.41 933.91 270.30
        Q 931.91 268.71 933.49 266.61
        Q 942.45 254.70 952.77 244.21
        Q 954.91 242.03 957.43 240.15
        Q 958.78 239.13 960.15 239.98
        Q 966.63 244.00 973.67 249.45
        Q 976.63 251.74 978.41 254.85
        A 0.91 0.90 49.5 0 0 979.89 254.97
        Q 988.48 244.49 997.41 236.40
        C 998.61 235.31 1000.36 234.49 1001.94 235.37
        C 1008.09 238.78 1013.62 242.89 1018.80 248.01
        C 1027.77 256.88 1023.35 265.68 1015.68 273.36
        Q 1009.03 280.01 1001.68 285.12
        Q 1000.19 286.15 998.75 285.13
        C 996.08 283.25 993.90 280.51 991.52 278.37
        Q 986.58 273.95 981.58 269.42
        Q 980.93 268.84 980.53 269.61
        Z"
      />
      <path class="logopathfont" d="
        M 1101.47 396.61
        Q 1094.63 397.66 1087.29 398.21
        Q 1081.08 398.68 1075.16 397.40
        C 1064.44 395.08 1058.54 386.23 1059.67 375.44
        Q 1060.63 366.38 1064.39 359.07
        Q 1068.72 350.66 1072.36 342.88
        Q 1074.71 337.88 1078.28 333.23
        C 1082.24 328.07 1089.80 324.97 1096.25 326.02
        C 1116.25 329.27 1117.36 353.56 1118.31 369.30
        Q 1118.34 369.93 1118.97 369.84
        Q 1131.94 367.86 1137.77 365.49
        Q 1147.02 361.75 1156.49 357.37
        A 1.34 1.33 -36.0 0 0 1156.84 355.18
        Q 1151.31 350.05 1148.20 344.05
        C 1144.02 336.02 1145.26 323.26 1147.82 315.19
        C 1151.90 302.34 1157.03 291.69 1167.01 282.27
        Q 1175.56 274.20 1186.65 273.10
        C 1194.08 272.36 1203.02 273.59 1208.32 278.80
        C 1214.44 284.82 1218.11 292.26 1218.59 300.96
        C 1219.17 311.75 1217.74 322.52 1212.98 331.78
        Q 1211.13 335.39 1209.41 338.99
        A 0.43 0.43 0.0 0 0 1209.68 339.59
        C 1217.86 342.02 1227.18 337.17 1233.98 333.04
        C 1242.28 327.99 1244.36 318.62 1244.69 309.40
        Q 1245.02 300.15 1245.31 291.49
        C 1245.57 283.58 1247.86 275.94 1252.27 268.95
        Q 1255.30 264.14 1258.52 258.80
        C 1259.82 256.64 1262.92 258.34 1264.49 259.25
        A 1.69 1.68 20.4 0 1 1265.31 261.02
        C 1264.83 263.64 1264.25 266.48 1264.05 269.10
        Q 1262.54 289.14 1259.87 305.99
        Q 1258.65 313.66 1255.87 322.36
        Q 1253.81 328.82 1249.26 335.77
        C 1242.87 345.57 1233.19 355.55 1222.60 360.29
        C 1216.37 363.08 1204.67 367.26 1198.18 364.63
        Q 1196.23 363.84 1194.24 363.86
        Q 1189.30 363.90 1184.20 363.76
        A 3.80 3.71 28.8 0 0 1182.01 364.37
        Q 1174.77 369.07 1167.48 373.69
        C 1161.76 377.32 1155.39 380.38 1149.31 383.53
        Q 1144.61 385.97 1137.34 388.19
        Q 1127.60 391.15 1117.10 393.65
        A 1.16 1.16 0.0 0 0 1116.22 394.60
        Q 1114.45 405.59 1110.18 415.82
        C 1108.00 421.05 1106.20 426.20 1103.18 431.10
        C 1098.64 438.45 1093.86 447.01 1085.78 450.55
        C 1077.69 454.08 1069.44 457.81 1060.93 460.82
        Q 1045.19 466.36 1029.36 471.43
        A 1.21 1.20 -22.9 0 1 1027.94 470.86
        C 1027.06 469.27 1025.89 465.50 1027.97 464.34
        Q 1031.45 462.39 1034.55 460.38
        Q 1055.81 446.58 1075.50 430.50
        Q 1086.67 421.37 1094.12 412.61
        Q 1099.29 406.52 1102.64 398.07
        Q 1103.33 396.33 1101.47 396.61
        Z
        M 1188.77 338.82
        C 1197.04 333.41 1205.81 326.62 1201.56 315.15
        C 1197.89 305.23 1190.17 301.41 1179.95 302.56
        C 1171.73 303.49 1161.41 308.62 1160.25 317.72
        Q 1158.84 328.81 1167.40 334.33
        Q 1175.02 339.24 1185.09 339.79
        Q 1187.13 339.90 1188.77 338.82
        Z"
      />
      <path fill="none" d="
        M 1201.56 315.15
        C 1205.81 326.62 1197.04 333.41 1188.77 338.82
        Q 1187.13 339.90 1185.09 339.79
        Q 1175.02 339.24 1167.40 334.33
        Q 1158.84 328.81 1160.25 317.72
        C 1161.41 308.62 1171.73 303.49 1179.95 302.56
        C 1190.17 301.41 1197.89 305.23 1201.56 315.15
        Z"
      />
      <path class="logopathfont" d="
        M 438.40 369.05
        C 441.05 362.66 444.28 356.59 446.55 350.33
        Q 449.59 341.93 452.54 333.96
        A 1.26 1.26 0.0 0 1 454.00 333.16
        L 460.18 334.50
        Q 461.12 334.70 460.91 335.65
        C 458.52 345.91 456.08 356.47 452.70 366.50
        C 449.67 375.50 447.13 384.92 443.14 393.74
        C 440.15 400.35 437.64 407.27 434.02 413.85
        Q 431.09 419.18 428.33 423.94
        Q 423.14 432.92 416.26 440.35
        C 412.35 444.57 406.22 446.92 400.92 448.90
        Q 396.09 450.71 391.06 451.97
        Q 382.02 454.25 372.26 457.08
        C 365.34 459.09 358.31 460.59 351.25 462.13
        A 1.32 1.31 -19.1 0 1 349.79 461.43
        Q 348.88 459.58 348.56 457.32
        A 1.29 1.23 59.5 0 1 348.77 456.42
        Q 349.47 455.40 350.55 454.80
        Q 357.73 450.78 359.82 449.30
        Q 390.70 427.38 417.69 400.71
        C 422.49 395.98 426.73 390.96 430.30 384.89
        Q 435.54 375.98 438.40 369.05
        Z"
      />
      <path class="logopathfont" d="
        M 946.18 449.87
        C 937.76 443.42 936.75 434.38 937.63 424.05
        Q 938.89 409.48 943.70 399.29
        Q 950.17 385.61 957.90 377.04
        C 959.62 375.14 957.73 372.96 957.94 370.81
        C 958.75 362.84 962.84 355.96 967.34 348.96
        C 970.95 343.33 975.42 337.07 983.01 338.62
        C 998.95 341.86 1009.93 356.18 1013.08 371.45
        C 1015.43 382.87 1015.01 398.09 1011.09 409.21
        Q 1007.66 418.96 1003.28 426.24
        C 996.32 437.83 983.89 449.45 970.22 452.55
        C 962.60 454.28 952.70 454.87 946.18 449.87
        Z
        M 983.16 428.31
        C 993.41 422.97 996.20 413.65 993.24 402.55
        Q 991.71 396.82 987.03 390.22
        C 983.54 385.28 976.59 381.60 970.84 380.09
        A 2.22 2.20 -63.2 0 0 968.56 380.85
        C 965.59 384.57 962.37 388.19 960.22 392.42
        C 955.72 401.30 950.34 420.65 960.53 427.96
        C 966.95 432.57 976.73 431.65 983.16 428.31
        Z"
      />
      <path fill="none" d="
        M 983.16 428.31
        C 976.73 431.65 966.95 432.57 960.53 427.96
        C 950.34 420.65 955.72 401.30 960.22 392.42
        C 962.37 388.19 965.59 384.57 968.56 380.85
        A 2.22 2.20 -63.2 0 1 970.84 380.09
        C 976.59 381.60 983.54 385.28 987.03 390.22
        Q 991.71 396.82 993.24 402.55
        C 996.20 413.65 993.41 422.97 983.16 428.31
        Z"
      />
      <path class="logopathfont" d="
        M 78.14 636.35
        A 0.25 0.24 -83.5 0 0 77.84 636.59
        Q 77.78 647.41 77.86 657.51
        C 77.87 659.92 77.95 664.72 80.84 665.60
        Q 83.02 666.28 85.38 666.81
        Q 86.54 667.07 85.71 667.92
        Q 85.43 668.20 85.07 668.21
        Q 75.39 668.29 67.24 668.13
        Q 66.63 668.12 66.59 667.52
        L 66.57 667.26
        Q 66.55 667.04 66.76 666.99
        L 73.33 665.26
        A 1.05 1.05 0.0 0 0 74.04 664.62
        Q 75.12 661.76 75.18 658.58
        Q 75.38 646.57 75.52 634.85
        C 75.56 630.91 70.60 629.89 67.75 629.24
        Q 66.89 629.05 66.74 628.45
        A 0.74 0.74 0.0 0 1 67.45 627.54
        L 80.98 627.36
        A 2.18 2.17 -20.0 0 1 82.68 628.15
        Q 91.02 638.26 101.20 649.95
        Q 103.45 652.54 105.74 656.04
        A 0.50 0.49 43.5 0 0 106.58 656.02
        Q 106.86 655.54 106.85 654.98
        C 106.79 647.18 107.10 639.24 105.66 632.06
        A 1.98 1.95 -85.7 0 0 104.44 630.62
        Q 102.01 629.70 99.47 628.82
        Q 98.86 628.61 99.02 628.00
        A 0.85 0.85 0.0 0 1 99.85 627.38
        Q 107.55 627.53 114.97 627.31
        A 1.60 1.52 -15.6 0 1 116.37 628.05
        Q 116.56 628.37 115.72 628.70
        C 113.86 629.43 110.16 629.99 109.83 632.37
        Q 108.88 639.28 109.17 648.34
        Q 109.48 657.77 109.48 667.21
        Q 109.48 668.38 108.30 668.46
        C 106.84 668.56 105.34 668.26 104.42 667.00
        Q 93.06 651.55 79.96 637.41
        Q 79.23 636.62 78.14 636.35
        Z"
      />
      <path class="logopathfont" d="
        M 502.20 656.98
        C 503.19 653.83 504.10 650.68 505.48 647.66
        Q 508.30 641.50 510.42 635.75
        Q 511.14 633.80 511.25 631.73
        A 1.37 1.36 -79.2 0 0 510.32 630.35
        L 506.27 628.98
        A 0.70 0.69 -60.2 0 1 505.97 627.87
        Q 506.41 627.35 507.10 627.36
        Q 513.14 627.40 519.64 627.35
        A 1.42 1.20 49.7 0 1 520.06 627.42
        Q 521.14 627.82 520.83 628.35
        Q 520.56 628.82 520.05 628.98
        C 517.30 629.85 515.00 631.02 513.93 633.92
        Q 511.51 640.40 508.27 646.48
        Q 506.13 650.51 504.46 655.60
        Q 502.45 661.75 499.53 667.75
        Q 499.33 668.15 498.92 668.31
        C 497.61 668.83 496.38 668.15 495.96 666.84
        Q 492.58 656.06 488.51 646.14
        Q 488.20 645.38 487.60 644.77
        A 0.69 0.68 28.9 0 0 486.44 645.10
        Q 485.08 651.23 482.09 657.36
        Q 480.12 661.40 478.21 665.80
        Q 475.79 671.36 473.98 665.57
        Q 473.37 663.62 472.61 661.83
        Q 469.36 654.12 467.08 646.98
        Q 464.29 638.22 461.81 632.72
        Q 460.55 629.93 456.75 628.75
        Q 456.13 628.56 456.42 627.98
        Q 456.65 627.53 457.16 627.53
        Q 465.23 627.46 473.38 627.42
        Q 474.05 627.42 474.56 627.83
        Q 475.21 628.37 474.39 628.57
        C 472.33 629.10 468.93 629.54 470.29 632.71
        Q 475.25 644.24 478.73 657.08
        A 0.41 0.40 -35.1 0 0 479.45 657.20
        Q 482.71 652.62 484.00 647.25
        Q 484.50 645.22 485.39 643.41
        C 487.02 640.08 484.80 636.31 483.51 633.20
        Q 482.17 629.99 477.98 628.74
        A 0.54 0.54 0.0 0 1 477.83 627.77
        Q 478.40 627.38 479.24 627.39
        Q 487.81 627.54 496.20 627.30
        A 0.70 0.69 -84.5 0 1 496.90 628.15
        Q 496.75 628.83 496.00 628.98
        Q 494.39 629.31 493.02 629.96
        A 1.60 1.60 0.0 0 0 492.14 631.77
        Q 495.17 644.95 501.16 657.06
        Q 501.78 658.30 502.20 656.98
        Z"
      />
      <path class="logopathfont" d="
        M 736.48 643.77
        Q 736.44 645.19 737.87 645.19
        L 756.49 645.14
        A 0.81 0.81 0.0 0 0 757.29 644.35
        Q 757.37 639.64 757.27 634.26
        C 757.20 630.05 755.42 629.87 751.95 629.01
        Q 751.55 628.91 751.32 628.58
        Q 751.14 628.30 751.25 628.00
        A 0.96 0.95 -79.2 0 1 752.14 627.39
        Q 761.52 627.45 770.79 627.30
        Q 771.45 627.29 772.03 627.59
        Q 773.54 628.36 771.89 628.75
        C 769.68 629.27 766.08 629.81 766.00 632.85
        Q 765.57 650.57 765.95 662.68
        C 766.05 666.14 770.19 666.19 772.58 666.66
        A 0.64 0.64 0.0 0 1 773.00 667.63
        Q 772.78 667.98 772.28 668.01
        Q 761.92 668.80 751.46 667.92
        A 0.73 0.73 0.0 0 1 750.85 667.47
        Q 750.56 666.76 751.13 666.62
        C 753.74 665.96 757.07 666.02 757.20 662.37
        Q 757.48 654.89 757.34 648.06
        A 0.93 0.93 0.0 0 0 756.44 647.15
        Q 746.70 646.79 737.10 647.32
        A 0.57 0.57 0.0 0 0 736.56 647.89
        Q 736.60 654.63 736.51 660.76
        Q 736.48 663.60 737.57 664.90
        A 1.72 1.71 -12.4 0 0 738.45 665.46
        Q 740.73 666.09 743.18 666.65
        Q 743.93 666.81 743.69 667.54
        A 0.74 0.73 -82.2 0 1 743.04 668.04
        Q 732.29 668.76 722.40 668.04
        Q 721.80 668.00 721.46 667.49
        A 0.47 0.47 0.0 0 1 721.72 666.77
        L 726.45 665.45
        Q 726.99 665.30 727.27 664.81
        Q 727.89 663.71 727.91 662.25
        Q 728.10 647.67 727.99 632.48
        A 2.53 2.53 0.0 0 0 726.28 630.10
        Q 724.49 629.50 722.62 628.84
        Q 721.90 628.59 722.22 627.93
        A 0.97 0.97 0.0 0 1 723.11 627.38
        Q 732.33 627.55 742.29 627.35
        Q 743.00 627.33 743.43 627.90
        A 0.53 0.52 -25.2 0 1 743.13 628.72
        C 741.22 629.19 738.57 629.61 737.22 631.16
        Q 736.76 631.69 736.74 632.40
        L 736.48 643.77
        Z"
      />
      <path class="logopathfont" d="
        M 957.82 630.35
        Q 955.65 629.34 952.70 629.00
        A 0.45 0.45 0.0 0 1 952.30 628.51
        L 952.33 628.21
        A 0.83 0.83 0.0 0 1 953.14 627.47
        Q 968.12 627.31 979.61 627.58
        Q 993.92 627.91 1000.01 640.69
        Q 1003.41 647.82 1001.21 654.59
        Q 996.99 667.56 983.98 667.91
        Q 973.65 668.18 963.95 668.43
        C 960.22 668.52 956.42 668.20 952.70 668.00
        A 0.71 0.71 0.0 0 1 952.08 667.03
        Q 952.21 666.70 952.54 666.58
        C 955.79 665.41 958.67 666.29 958.70 661.50
        Q 958.80 646.84 958.72 631.77
        A 1.57 1.56 -77.6 0 0 957.82 630.35
        Z
        M 986.94 663.23
        C 993.87 657.18 994.71 646.00 990.40 638.45
        C 987.72 633.75 984.46 630.20 978.88 629.37
        Q 973.36 628.55 968.21 629.70
        A 1.13 1.13 0.0 0 0 967.33 630.80
        L 967.40 663.78
        A 1.29 1.28 7.3 0 0 968.37 665.02
        C 973.24 666.26 982.68 666.95 986.94 663.23
        Z"
      />
      <path class="logopathfont" d="
        M 1220.95 631.11
        A 1.73 1.72 -88.6 0 0 1219.29 629.36
        Q 1216.00 629.25 1213.12 630.25
        C 1209.37 631.54 1208.85 635.62 1207.73 638.95
        Q 1207.53 639.53 1206.92 639.51
        Q 1206.43 639.50 1206.21 638.69
        A 1.29 1.04 -57.4 0 1 1206.18 638.42
        Q 1206.45 633.15 1206.41 628.20
        A 0.83 0.83 0.0 0 1 1207.25 627.36
        L 1243.39 627.49
        A 0.75 0.75 0.0 0 1 1244.13 628.26
        Q 1243.96 632.93 1244.56 638.01
        Q 1244.64 638.76 1244.30 639.39
        A 0.59 0.58 50.1 0 1 1243.23 639.29
        C 1242.59 637.35 1240.61 631.68 1238.80 630.90
        Q 1235.37 629.44 1231.09 629.25
        A 1.69 1.68 -89.0 0 0 1229.33 630.96
        Q 1229.49 644.44 1229.42 658.50
        Q 1229.40 661.59 1229.75 664.38
        A 1.86 1.85 -87.8 0 0 1231.21 665.96
        L 1236.05 667.00
        A 0.43 0.42 -68.3 0 1 1236.32 667.64
        Q 1236.08 668.01 1235.60 668.05
        Q 1223.91 668.87 1214.75 667.97
        A 0.24 0.24 0.0 0 1 1214.53 667.70
        L 1214.57 667.38
        Q 1214.62 666.95 1215.05 666.87
        L 1219.07 666.14
        Q 1219.65 666.04 1219.91 665.51
        Q 1220.72 663.89 1220.72 662.17
        Q 1220.75 646.40 1220.95 631.11
        Z"
      />
      <path class="logopathfont" d="
        M 206.73 665.05
        Q 208.33 665.35 209.53 666.26
        Q 211.85 668.02 208.94 668.17
        Q 202.29 668.50 195.97 668.01
        Q 195.32 667.96 195.16 667.35
        A 0.85 0.85 0.0 0 1 195.84 666.29
        Q 200.04 665.55 202.53 660.44
        Q 204.51 656.37 205.74 652.83
        C 207.60 647.49 209.97 642.53 212.86 637.74
        Q 214.95 634.26 214.48 630.07
        Q 214.39 629.24 215.20 629.02
        L 220.39 627.60
        Q 221.76 627.22 222.22 628.57
        Q 226.67 641.87 231.84 654.04
        C 233.24 657.32 234.08 660.73 235.54 663.95
        A 1.73 1.69 3.4 0 0 236.21 664.69
        Q 238.58 666.11 241.31 666.44
        A 0.89 0.88 88.2 0 1 242.07 667.16
        Q 242.15 667.57 241.82 667.83
        Q 241.52 668.07 241.11 668.10
        Q 230.73 668.81 220.48 668.09
        A 0.94 0.94 0.0 0 1 219.60 667.23
        Q 219.55 666.58 220.15 666.39
        C 222.73 665.57 227.02 666.30 225.59 661.73
        Q 223.95 656.49 222.21 651.61
        A 1.36 1.35 -9.0 0 0 220.96 650.71
        L 210.79 650.47
        Q 209.37 650.44 208.87 651.76
        Q 206.60 657.70 205.36 662.93
        Q 204.94 664.71 206.73 665.05
        Z
        M 210.59 647.70
        A 0.54 0.54 0.0 0 0 211.07 648.46
        L 219.81 648.58
        A 0.54 0.54 0.0 0 0 220.33 647.87
        L 216.58 636.62
        A 0.54 0.54 0.0 0 0 215.57 636.57
        L 210.59 647.70
        Z"
      />
      <path class="logopathfont" d="
        M 353.17 627.57
        C 363.42 628.27 372.63 635.44 373.38 646.11
        Q 373.99 654.85 369.26 660.53
        Q 364.98 665.66 359.90 666.82
        Q 350.24 669.03 341.49 667.57
        C 328.67 665.42 323.11 654.57 325.73 642.54
        C 327.33 635.18 333.80 629.67 341.17 628.15
        Q 346.22 627.10 353.17 627.57
        Z
        M 355.58 665.31
        C 368.23 660.14 367.08 631.27 352.26 629.23
        C 348.51 628.71 343.41 628.97 340.44 631.67
        C 332.94 638.49 332.22 653.62 338.28 662.16
        Q 339.53 663.91 341.58 664.81
        Q 348.92 668.03 355.58 665.31
        Z"
      />
      <path class="logopathfont" d="
        M 604.15 664.84
        L 607.78 666.19
        Q 608.39 666.42 608.55 667.06
        Q 608.66 667.49 608.34 667.76
        Q 608.00 668.05 607.56 668.08
        Q 600.39 668.56 593.71 668.02
        A 0.78 0.78 0.0 0 1 593.03 667.00
        C 593.44 665.72 596.79 665.73 597.95 663.97
        Q 602.31 657.34 604.29 651.53
        C 606.32 645.56 608.89 640.47 611.76 635.08
        C 612.77 633.19 612.68 631.64 612.65 629.62
        Q 612.65 629.00 613.25 628.84
        L 618.14 627.60
        A 1.34 1.32 -18.9 0 1 619.69 628.35
        Q 622.43 634.49 624.54 640.91
        C 627.04 648.50 630.55 655.71 633.17 663.08
        C 634.02 665.45 636.80 666.00 639.01 666.37
        A 0.91 0.90 -5.0 0 1 639.71 666.95
        Q 639.81 667.24 639.73 667.54
        Q 639.59 668.08 639.03 668.12
        Q 629.17 668.85 618.47 668.04
        Q 617.81 667.99 617.47 667.46
        A 0.75 0.75 0.0 0 1 617.96 666.31
        L 622.93 665.34
        Q 623.52 665.22 623.63 664.64
        Q 624.03 662.51 623.24 660.35
        Q 621.62 655.89 620.00 651.72
        A 1.88 1.87 -10.9 0 0 618.24 650.53
        L 608.23 650.61
        A 1.51 1.50 8.5 0 0 606.81 651.65
        Q 605.31 656.34 603.77 660.94
        Q 603.29 662.40 603.44 663.93
        Q 603.51 664.61 604.15 664.84
        Z
        M 608.09 647.56
        A 0.60 0.60 0.0 0 0 608.62 648.42
        L 617.52 648.60
        A 0.60 0.60 0.0 0 0 618.11 647.82
        L 614.55 636.61
        A 0.60 0.60 0.0 0 0 613.44 636.52
        L 608.09 647.56
        Z"
      />
      <path class="logopathfont" d="
        M 1114.30 664.34
        Q 1114.59 662.68 1114.05 661.20
        Q 1112.43 656.77 1110.20 651.77
        A 2.11 2.10 -12.2 0 0 1108.26 650.52
        L 1098.83 650.65
        A 1.31 1.30 -80.2 0 0 1097.62 651.51
        L 1093.69 662.13
        A 2.20 2.19 -67.4 0 0 1094.81 664.87
        Q 1096.71 665.77 1098.79 666.53
        Q 1099.27 666.71 1099.27 667.18
        A 0.86 0.86 0.0 0 1 1098.47 668.04
        Q 1091.29 668.58 1083.94 668.02
        Q 1083.41 667.98 1083.14 667.53
        Q 1082.89 667.12 1083.35 666.63
        A 1.20 1.15 -74.0 0 1 1083.92 666.30
        Q 1088.37 665.34 1089.95 661.60
        C 1091.82 657.18 1094.39 653.01 1096.06 648.49
        Q 1098.75 641.18 1102.06 634.78
        C 1102.91 633.14 1102.86 631.51 1102.87 629.75
        A 0.87 0.86 82.9 0 1 1103.50 628.93
        L 1108.61 627.54
        A 0.92 0.91 -20.6 0 1 1109.66 628.01
        Q 1112.96 634.55 1115.32 641.58
        Q 1118.98 652.51 1123.90 663.43
        C 1124.76 665.34 1127.11 666.04 1129.01 666.23
        A 0.78 0.76 1.4 0 1 1129.70 666.94
        L 1129.73 667.36
        Q 1129.77 668.07 1129.07 668.12
        Q 1119.00 668.89 1108.98 667.96
        Q 1108.32 667.90 1107.84 667.52
        A 0.55 0.55 0.0 0 1 1108.05 666.56
        L 1113.42 665.28
        Q 1114.17 665.10 1114.30 664.34
        Z
        M 1098.97 646.96
        A 1.05 1.05 0.0 0 0 1099.92 648.40
        L 1107.52 648.62
        A 1.05 1.05 0.0 0 0 1108.54 647.25
        L 1105.05 636.67
        A 1.05 1.05 0.0 0 0 1103.08 636.61
        L 1098.97 646.96
        Z"
      />
      <path class="logopathfont" d="
        M 1343.99 666.18
        Q 1343.23 665.98 1342.91 666.80
        A 0.80 0.79 -78.8 0 0 1343.63 667.88
        L 1351.33 668.12
        Q 1340.21 668.61 1329.26 667.87
        Q 1328.59 667.83 1328.23 667.57
        A 0.50 0.50 0.0 0 1 1328.38 666.69
        C 1331.74 665.70 1334.55 665.75 1334.67 661.22
        Q 1334.99 648.26 1334.65 634.17
        C 1334.56 630.32 1331.62 629.91 1328.79 628.79
        Q 1328.27 628.58 1328.47 628.05
        Q 1328.68 627.50 1329.25 627.50
        Q 1347.12 627.47 1364.98 627.49
        A 0.78 0.78 0.0 0 1 1365.76 628.32
        Q 1365.49 632.97 1366.10 637.82
        Q 1366.18 638.41 1365.59 638.50
        Q 1364.90 638.60 1364.59 637.96
        C 1362.85 634.34 1360.46 630.92 1356.25 629.96
        Q 1350.77 628.71 1344.39 629.26
        A 1.68 1.67 -2.9 0 0 1342.85 630.96
        L 1343.07 644.04
        A 1.44 1.44 0.0 0 0 1344.53 645.46
        C 1350.31 645.35 1354.26 643.65 1356.75 638.19
        A 0.69 0.69 0.0 0 1 1357.94 638.08
        Q 1358.22 638.49 1358.14 639.00
        Q 1356.86 646.54 1358.24 654.71
        Q 1358.33 655.28 1357.77 655.18
        L 1357.44 655.12
        Q 1356.82 655.02 1356.67 654.41
        Q 1355.14 648.29 1349.17 647.31
        Q 1346.53 646.88 1343.77 647.10
        A 0.99 0.98 -2.5 0 0 1342.87 648.08
        Q 1342.91 656.55 1343.02 664.79
        C 1343.03 665.38 1343.45 665.96 1343.99 666.18
        Z"
      />
      <path class="logopathfont" d="
        M 1479.17 661.58
        C 1479.50 656.64 1475.76 654.98 1471.55 652.98
        Q 1465.12 649.93 1460.12 647.04
        C 1454.83 643.99 1453.09 637.78 1456.10 632.56
        Q 1458.58 628.26 1464.47 627.65
        Q 1470.55 627.01 1475.55 628.04
        Q 1479.50 628.85 1483.53 629.37
        A 0.58 0.57 5.0 0 1 1484.03 629.96
        Q 1483.85 634.75 1484.27 639.50
        Q 1484.29 639.73 1484.22 639.96
        Q 1484.09 640.41 1483.34 640.34
        A 0.79 0.78 81.6 0 1 1482.70 639.86
        C 1481.18 636.10 1478.28 631.80 1474.60 630.13
        C 1471.98 628.94 1464.29 628.02 1462.83 631.96
        Q 1461.02 636.83 1465.46 639.36
        Q 1470.23 642.08 1477.01 645.10
        C 1480.70 646.74 1484.83 649.75 1486.06 653.61
        C 1487.92 659.39 1485.21 665.59 1479.18 667.11
        Q 1472.98 668.69 1466.87 667.90
        C 1463.29 667.43 1459.87 667.33 1456.26 667.78
        A 0.63 0.62 -7.1 0 1 1455.57 667.25
        Q 1454.83 661.56 1453.31 655.60
        Q 1453.16 655.00 1453.54 654.52
        A 0.46 0.45 47.8 0 1 1454.29 654.56
        Q 1456.41 658.00 1459.25 661.58
        C 1463.03 666.32 1471.04 667.64 1476.43 665.58
        Q 1478.96 664.61 1479.17 661.58
        Z"
      />
      <path fill="none" d="
        M 352.26 629.23
        C 367.08 631.27 368.23 660.14 355.58 665.31
        Q 348.92 668.03 341.58 664.81
        Q 339.53 663.91 338.28 662.16
        C 332.22 653.62 332.94 638.49 340.44 631.67
        C 343.41 628.97 348.51 628.71 352.26 629.23
        Z"
      />
      <path fill="none" d="
        M 990.40 638.45
        C 994.71 646.00 993.87 657.18 986.94 663.23
        C 982.68 666.95 973.24 666.26 968.37 665.02
        A 1.29 1.28 7.3 0 1 967.40 663.78
        L 967.33 630.80
        A 1.13 1.13 0.0 0 1 968.21 629.70
        Q 973.36 628.55 978.88 629.37
        C 984.46 630.20 987.72 633.75 990.40 638.45
        Z"
      />
      <path fill="none" d="
        M 1098.97 646.96
        L 1103.08 636.61
        A 1.05 1.05 0.0 0 1 1105.05 636.67
        L 1108.54 647.25
        A 1.05 1.05 0.0 0 1 1107.52 648.62
        L 1099.92 648.40
        A 1.05 1.05 0.0 0 1 1098.97 646.96
        Z"
      />
      <path fill="none" d="
        M 608.09 647.56
        L 613.44 636.52
        A 0.60 0.60 0.0 0 1 614.55 636.61
        L 618.11 647.82
        A 0.60 0.60 0.0 0 1 617.52 648.60
        L 608.62 648.42
        A 0.60 0.60 0.0 0 1 608.09 647.56
        Z"
      />
      <path fill="none" d="
        M 210.59 647.70
        L 215.57 636.57
        A 0.54 0.54 0.0 0 1 216.58 636.62
        L 220.33 647.87
        A 0.54 0.54 0.0 0 1 219.81 648.58
        L 211.07 648.46
        A 0.54 0.54 0.0 0 1 210.59 647.70
        Z"
      />
      <path class="logopathfont" d="
        M 1351.33 668.12
        L 1343.63 667.88
        A 0.80 0.79 -78.8 0 1 1342.91 666.80
        Q 1343.23 665.98 1343.99 666.18
        Q 1351.72 667.56 1359.74 664.98
        C 1364.96 663.30 1367.04 660.18 1369.69 655.29
        A 0.40 0.40 0.0 0 1 1370.28 655.16
        L 1370.50 655.33
        A 0.88 0.87 -59.4 0 1 1370.77 656.39
        Q 1368.36 661.81 1366.96 666.52
        A 2.37 2.36 -81.5 0 1 1364.68 668.21
        L 1351.33 668.12
        Z"
      />
      <path class="logopathfont" d="
        M 352.05 680.15
        C 345.61 676.20 338.08 671.35 330.04 673.84
        Q 326.84 674.83 329.40 672.67
        Q 330.05 672.13 331.22 671.87
        Q 338.63 670.22 345.55 672.17
        Q 351.19 673.77 357.54 675.55
        Q 367.79 678.41 374.42 672.18
        A 0.71 0.71 0.0 0 1 375.60 672.56
        Q 375.80 673.58 374.99 674.47
        C 373.23 676.41 371.50 678.32 369.54 679.96
        C 364.89 683.85 356.78 683.04 352.05 680.15
        Z"
      />

      </svg>
    </div>

    <!-- Soon  -->
    <div class="soon">
      <!-- <span class="title-soon">Soon</span> -->
      <!-- Generate text that show text of " Coming Soon " for user  -->
        <!-- <span class="text-soon">Coming Soon</span> -->
        <span class="text-soon">قـريباً</span>
    </div>
    

    <!--  Social Media  -->
    <!-- Icons Sources
    https://icons8.com/icons/set/insta
  https://www.svgrepo.com/vectors/instagram/3
 -->
    <div class="social-media">
      <ul class="wrapper">
      <li class="icon facebook">
        <span class="tooltip">Facebook</span>
        <span>
          <svg width="40px" height="40px" viewBox="0 -1 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill="#cdcdcd" d="M20 12.05C19.9813 10.5255 19.5273 9.03809 18.6915 7.76295C17.8557 6.48781 16.673 5.47804 15.2826 4.85257C13.8921 4.2271 12.3519 4.01198 10.8433 4.23253C9.33473 4.45309 7.92057 5.10013 6.7674 6.09748C5.61422 7.09482 4.77005 8.40092 4.3343 9.86195C3.89856 11.323 3.88938 12.8781 4.30786 14.3442C4.72634 15.8103 5.55504 17.1262 6.69637 18.1371C7.83769 19.148 9.24412 19.8117 10.75 20.05V14.38H8.75001V12.05H10.75V10.28C10.7037 9.86846 10.7483 9.45175 10.8807 9.05931C11.0131 8.66687 11.23 8.30827 11.5161 8.00882C11.8022 7.70936 12.1505 7.47635 12.5365 7.32624C12.9225 7.17612 13.3368 7.11255 13.75 7.14003C14.3498 7.14824 14.9482 7.20173 15.54 7.30003V9.30003H14.54C14.3676 9.27828 14.1924 9.29556 14.0276 9.35059C13.8627 9.40562 13.7123 9.49699 13.5875 9.61795C13.4627 9.73891 13.3667 9.88637 13.3066 10.0494C13.2464 10.2125 13.2237 10.387 13.24 10.56V12.07H15.46L15.1 14.4H13.25V20C15.1399 19.7011 16.8601 18.7347 18.0985 17.2761C19.3369 15.8175 20.0115 13.9634 20 12.05Z"/>
            </svg>
        </span>
      </li>
      <li class="icon twitter">
        <span class="tooltip">X</span>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg"  
          viewBox="0 -3 48 48" width="28px" height="28px" clip-rule="evenodd" 
          baseProfile="basic">
          <polygon fill="#cdcdcd" points="41,6 9.929,42 6.215,42 37.287,6"/><polygon 
          fill="none" fill-rule="evenodd" points="31.143,41 7.82,7 16.777,7 40.1,41" clip-rule="evenodd"/>
          <path fill="#cdcdcd" d="M15.724,9l20.578,30h-4.106L11.618,9H15.724 M17.304,6H5.922l24.694,36h11.382L17.304,6L17.304,6z"/></svg>
        </span>
      </li>
      <li class="icon instagram">
        <span class="tooltip">Instagram</span>
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
           width="30px" height="30px" viewBox="0 -3 32 32">
            <path fill="#cdcdcd" d="M 11.46875 5 C 7.917969 5 5 7.914063 5 11.46875 L 5 20.53125 C 5 24.082031 7.914063 27 11.46875 27 L 20.53125 27 C 24.082031 27 27 24.085938 27 20.53125 L 27 11.46875 C 27 7.917969 24.085938 5 20.53125 5 Z M 11.46875 7 L 20.53125 7 C 23.003906 7 25 8.996094 25 11.46875 L 25 20.53125 C 25 23.003906 23.003906 25 20.53125 25 L 11.46875 25 C 8.996094 25 7 23.003906 7 20.53125 L 7 11.46875 C 7 8.996094 8.996094 7 11.46875 7 Z M 21.90625 9.1875 C 21.402344 9.1875 21 9.589844 21 10.09375 C 21 10.597656 21.402344 11 21.90625 11 C 22.410156 11 22.8125 10.597656 22.8125 10.09375 C 22.8125 9.589844 22.410156 9.1875 21.90625 9.1875 Z M 16 10 C 12.699219 10 10 12.699219 10 16 C 10 19.300781 12.699219 22 16 22 C 19.300781 22 22 19.300781 22 16 C 22 12.699219 19.300781 10 16 10 Z M 16 12 C 18.222656 12 20 13.777344 20 16 C 20 18.222656 18.222656 20 16 20 C 13.777344 20 12 18.222656 12 16 C 12 13.777344 13.777344 12 16 12 Z"></path>
            </svg>
        </span>
      </li>
      <li class="icon youtube">
        <span class="tooltip">Youtube</span>
        <span>
          <svg width="28px" height="28px" viewBox="0 -12 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title>Youtube-color</title>
            <desc>Created with Sketch.</desc>
            <defs>

        </defs>
            <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Color-" transform="translate(-200.000000, -368.000000)" fill="#cdcdcd">
                    <path d="M219.044,391.269916 L219.0425,377.687742 L232.0115,384.502244 L219.044,391.269916 Z M247.52,375.334163 C247.52,375.334163 247.0505,372.003199 245.612,370.536366 C243.7865,368.610299 241.7405,368.601235 240.803,368.489448 C234.086,368 224.0105,368 224.0105,368 L223.9895,368 C223.9895,368 213.914,368 207.197,368.489448 C206.258,368.601235 204.2135,368.610299 202.3865,370.536366 C200.948,372.003199 200.48,375.334163 200.48,375.334163 C200.48,375.334163 200,379.246723 200,383.157773 L200,386.82561 C200,390.73817 200.48,394.64922 200.48,394.64922 C200.48,394.64922 200.948,397.980184 202.3865,399.447016 C204.2135,401.373084 206.612,401.312658 207.68,401.513574 C211.52,401.885191 224,402 224,402 C224,402 234.086,401.984894 240.803,401.495446 C241.7405,401.382148 243.7865,401.373084 245.612,399.447016 C247.0505,397.980184 247.52,394.64922 247.52,394.64922 C247.52,394.64922 248,390.73817 248,386.82561 L248,383.157773 C248,379.246723 247.52,375.334163 247.52,375.334163 L247.52,375.334163 Z" id="Youtube">

        </path>
                </g>
            </g>
        </svg>

        </span>
      </li>
    </ul>
    </div>




      </div>
</template>



<script>
// import myScript from './JS/soonfont.js';
export default {
  name: "ComingSoonB",
  // rest of your component options...
};

</script>

<style lang="scss">

//@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css');
//@import url('//maxcdn.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.min.css');

path {
  /* stroke: #2f4439; */
  stroke-width: 1px;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 4;
  stroke-opacity: 1;
}
/*2d4538*/
.logopathfont{
  /* fill: #c3ae9d; orignal from doc */
  fill: #dccbbd;
  /* background-color: aliceblue;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s linear forwards; */


}
/*genereate style for svgcontainer*/

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  /* background-image: url('/images/img13.jpg');
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center; */
}

/* make svgContainer in middle and make it streach if desplayed for phone*/
#Container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/images/img02.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  filter: contrast(127%);

}
#Container3{
  width: 100%;
  height: 100%;
  background-color: #2f4439e1;
}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  #Container3 {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

#Container2{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#svgLogo{
  height: 40%;
  width: auto;
  z-index: 1;

  padding-top: 5%;
}

#logo{
  height: 100%;
  width: auto;
}

@media screen and (min-width: 1024px) {
  #Container {
    position: absolute;
    background-position-y:center;
    background-position : bottom;
  }
}

@media screen and (max-width: 1024px) {
  #svgLogo{
    block-size:auto;
    padding-top: 15%;
    margin: 5%;
  }
  #logo{
    height: 60%;
    width: auto;
  }
}

/* Social Media */
.social-media {
  position: absolute;
  width: 100%;
  padding: 20px;

}


/*
    Auther: Abdelrhman Said
*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus,
*:active {
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
}


.wrapper {
  display: inline-flex;
  list-style: none;
}

.wrapper .icon {
  position: relative;
  background: #ffffff1f;
  color: #cdcdcd;
  border-radius: 50%;
  padding: 15px;
  margin: 10px;
  width: 50px;
  height: 50px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip {
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #ffffff;
  color: #ffffff;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .tooltip::before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  background: #ffffff;
  bottom: -3px;
  left: 50%;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper .icon:hover .tooltip {
  top: -45px;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.wrapper .icon:hover span,
.wrapper .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.wrapper .facebook:hover,
.wrapper .facebook:hover .tooltip,
.wrapper .facebook:hover .tooltip::before {
  background: #1877F2;
  color: #ffffff;
}

.wrapper .twitter:hover,
.wrapper .twitter:hover .tooltip,
.wrapper .twitter:hover .tooltip::before {
  background: #000000;
  color: #ffffff;
}

.wrapper .instagram:hover,
.wrapper .instagram:hover .tooltip,
.wrapper .instagram:hover .tooltip::before {
  color: #ffffff;

  /*https://stackoverflow.com/questions/37751375/instagram-new-logo-css-background*/
  background: #f09433; 
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
    
}

.wrapper .youtube:hover,
.wrapper .youtube:hover .tooltip,
.wrapper .youtube:hover .tooltip::before {
  background: #CD201F;
  color: #ffffff;
}




.text-soon{
  font-family: 'Farsi Simple';
  font-size: 7em; /* Change this to the font size you want */
  margin-top: 20px; /* Change this to the margin you want */
  margin-bottom: 20px;
  color: #cdcdcd; /* Change this to the color you want */
  text-align: center; /* Change this to the alignment you want */ 
}
/*
@media screen and (min-width: 1024px) {
  .soon {
    font-size: 5em; 
  }

  
}*/
/*https://stackoverflow.com/questions/51524014/how-to-add-font-in-vue-js-project*/
/*
@font-face {
  font-family: 'Vibes Dyn Subset';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/l/font?kit=QdVYSTsmIB6tmbdyHpDsuTNAs0LohKeRvR771cYAse6UIoUUuQzD_u5zF3gg0eath2A&skey=4912d45c76bf9864&v=v3) format('woff2');
}*/
/* arabic */
@font-face {
  font-family: 'Farsi Simple';
  src: url('/fonts/FRSSPBL.TTF')format('truetype');
  font-style: normal;
  font-weight: 400;
}

</style>